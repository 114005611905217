import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-button-renderer',
  template: `
  <button type="button" class="btn btn-link" (click)="onClick($event,1)"><fa-icon [icon]="faEdit"><span class="sr-only">Edit</span></fa-icon></button>
  <button type="button" class="btn btn-link " (click)="onClick($event,0)"><fa-icon [icon]="faWindowClose"><span class="sr-only">Delete</span></fa-icon></button>
    `
})

export class ButtonRendererComponent implements ICellRendererAngularComp {
  faEdit=faEdit;
  faWindowClose=faWindowClose;
  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event,mode) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        mode:mode
        // ...something
      }
      this.params.onClick(params);

    }
  }
}