import { Injectable } from '@angular/core';
import * as Msal from 'msal';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
  providedIn: 'root'
})

export class MsaluserService {
  private accessToken: any;
  public clientApplication: Msal.UserAgentApplication = null;


  private graphMeEndpoint = "https://graph.microsoft.com/v1.0/me";
  private loginRequest = {
    scopes: ["user.read", "user.write"],
    state: environment.redirectUri
  };

  private loginFailureSubscription: Subscription;
  private loginSuccessSubscription: Subscription;
  private acquireTokenSuccessSubscription: Subscription;
  private acquireTokenFailureSubscription: Subscription;

  public msalConfig = {
    auth: {
      clientId: environment.uiClienId,
      authority: "https://login.microsoftonline.com/" + environment.tenantId,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.postLogoutRedirectUri,
      navigateToLoginRequestUrl: true,
      authCallback: this.authCallback
    },
    graphScopes: ['user.read']
  };

  constructor(private http: HttpClient,
    private broadcastService: BroadcastService,
    private authService: MsalService) {
    this.clientApplication = new Msal.UserAgentApplication(this.msalConfig)
  }

  public initializeAuthentication() {
    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkoutAccount();
    });

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
    });


    this.loginFailureSubscription = this.broadcastService.subscribe
      ("msal:loginFailure", (payload) => {
        console.log("msal:loginFailure");
      });

    this.loginSuccessSubscription = this.broadcastService.subscribe
      ("msal:loginSuccess", (payload) => {
        console.log("msal:loginSuccess");

      });
    this.acquireTokenSuccessSubscription = this.broadcastService.subscribe("msal:acquireTokenSuccess", (payload) => {

      console.log("Market:acquireTokenSuccess");

    });

    this.acquireTokenFailureSubscription = this.broadcastService.subscribe("msal:acquireTokenFailure", (payload) => {
      console.log("msal:acquireTokenFailure");

    });
    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
      console.log('Redirect Success: ', response.accessToken);
    });
  }

  checkoutAccount() {
    let user = this.authService.getAccount();
    sessionStorage.setItem("username", user.name);
    sessionStorage.setItem("sid", user.sid);
  }
  public GetAccessToken(): Observable<any> {
    if (localStorage.getItem('msal.idtoken') !== undefined && localStorage.getItem('msal.idtoken') != null) {
      this.accessToken = localStorage.getItem('msal.idtoken');
    }
    return this.accessToken;
  }

  public authCallback(errorDesc, token, error, tokenType) {
    if (token) {
      this.authService.acquireTokenSilent(this.loginRequest);
    } else {
      console.log(error + ':' + errorDesc);
    }
  }

  public getCurrentUserInfo() {
    const user = this.authService.getAccount();
    if (user != null) {
      return user.name;
    }
  }

  public login() {
    return this.authService.loginPopup(this.loginRequest)
      .then(idToken => {
        const user = this.clientApplication.getAccount();
        if (user) {
          return user;
        } else {
          return null;
        }
      }, () => {
        return null;
      });
  }
  public logout() {
    this.authService.logout();
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    FileSaver.allowSave = 'true';
  }

}
