import { Component, OnInit } from '@angular/core';
import { MsaluserService } from '.././msaluser.service';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  fmpUrl: string = environment.fmpUrl
  fmpSupportUrl: string = environment.fmpUrl + "support/contact?selectedvalue=FluidNotListed"
  fspUrl: string = environment.redirectAdminUri 
  faSignOutAlt = faSignOutAlt;
  //fmpAdminUrl: string = environment.redirectUri;
  fmpAdminUrl: string = environment.redirectAdminUri + 'market';
  constructor(private msalService: MsaluserService,
    private authService: MsalService
  ) { }

  public userName: string;
  public loggedIn = false;
  ngOnInit() {
    this.loggedIn = !!this.authService.getAccount();
    this.userName = this.msalService.getCurrentUserInfo();
  }
  getCurrentUserInfo() {
    this.msalService.getCurrentUserInfo()
  }
  logout() {
    this.authService.logout();
  }

  login() {
    this.authService.loginRedirect();
  }

}
