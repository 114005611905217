import { Component, OnInit,ViewChild ,Input} from '@angular/core';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {SerialNumberService} from './serial-number.service';
import { SerialNumber } from './serial-number';
import { first } from 'rxjs/operators';
import { ButtonRendererComponent } from '../button-renderer/button-renderer.component';
@Component({
  selector: 'app-serial-number',
  templateUrl: './serial-number.component.html',
  styleUrls: ['./serial-number.component.css']
})
export class SerialNumberComponent implements OnInit {
  
  faEdit=faEdit;
  faWindowClose=faWindowClose;
  faSearch=faSearch;
  searchModel:any;
  serial:string
  showGrid:boolean=false
  gridData: SerialNumber[] = [];
  headerstring:string="Serial Number"
  form: FormGroup;
    id: number;
    isAddMode: boolean = true;
    loading = false;
    submitted = false;
    frameworkComponents: any;
    constructor(
     
      private formBuilder: FormBuilder,
      public SerialNumberService :SerialNumberService,
      private router: Router
      
    ) { 
      this.frameworkComponents = {
        buttonRenderer: ButtonRendererComponent,
      }
    }
  columnDefs = [
   
    { headerName: 'Serial Number',field: 'serialNumber' ,resizable: true},
    { headerName: 'Description',field: 'Description',resizable: true,minWidth: 350,flex: 2},
    { headerName: 'Status',field: 'status',minWidth:50,maxWidth:100},
    {
      headerName: 'Action',
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.onButtonRenderer.bind(this),
        label: 'Click 1'
      }
    },
  ];
  rowData: any;
  

  ngOnInit():void {
    this.serial =  "serial";
    this.form = this.formBuilder.group({
       serialNumber: ['', Validators.required],
        description: ['', Validators.required],
        production_date:['', Validators.required],
        active:['']
    });

    this.SerialNumberService.getAll().subscribe((data: SerialNumber[])=>{

      this.gridData = data;
    })  
  }

  get f() { return this.form.controls; }
  fnNewForm(){
    this.isAddMode = true; 

    this.form.reset();
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    if (this.isAddMode) {
        this.createModelFamily()
    } else {
      this.UpdateModelFamily()
    }
}
onButtonRenderer(e){
  //If mode 1 = edit; 0 = delete
  this.id = e.rowData.id;
  if(!e.mode){
    this.onDelete()
  }else{
    this.onEdit()
  }
 
}
onEdit(){
  this.isAddMode = false; 
    this.SerialNumberService.getById(this.id)
        .pipe(first())
        .subscribe(x => {
          this.form.patchValue(x[0])
        }          
          );    
}
onDelete(){
  this.SerialNumberService.delete(this.id).subscribe(res => {

    this.gridData = this.gridData.filter(item => item.id !== this.id);

    console.log('Post deleted successfully!');

})
}

private createModelFamily() {
  this.loading = false;
  this.form.reset();
 
  return;
  this.SerialNumberService.create(this.form.value).subscribe(res => {
    console.log('Family created successfully!');
    this.router.navigateByUrl('/model-family');

})
}

private UpdateModelFamily(){
  console.log(this.form.value);
  return
  this.SerialNumberService.update(this.id, this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {               
                  this.router.navigateByUrl('/model-family');
                },
                error: error => {                   
                   this.loading = false;
                }
            });
}

//Grid Functionality
 
  timeout = null;
searchFunc() {
  console.log(this.searchModel)
    if(this.searchModel != ''){
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.fetchResults(this.searchModel, 10)

    }, 500);
} else {
  
}

}

fetchResults(symbol, count) {
  this.SerialNumberService.getAll().subscribe((data: SerialNumber[])=>{
      //this.gridData = data;
      this.rowData=data
      this.showGrid = true
    })  
}


}


