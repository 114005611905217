import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FluidSelectorComponent } from './fluid-selector/fluid-selector.component'
import { ModelFamilyComponent } from './model-family/model-family.component';
import { SerialNumberComponent } from './serial-number/serial-number.component';
import { ModelNumberComponent } from './model-number/model-number.component';
import { MarketComponent } from './market/market.component';
import { ModelFamilyMappingComponent } from './model-family-mapping/model-family-mapping.component';
import { ModelSerialMappingComponent } from './model-serial-mapping/model-serial-mapping.component';
import { MarketModelFamilyMappingComponent } from './market-model-family-mapping/market-model-family-mapping.component';
import { ModelfamilySpecMappingComponent } from './modelfamily-spec-mapping/modelfamily-spec-mapping.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { AccessdeniedComponent } from './accessdenied/accessdenied.component'; AccessdeniedComponent
import { MsalGuard } from '@azure/msal-angular';
import { RoleGuard } from './role.guard';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: 'admin/market', component: MarketComponent,
    data: { roles: environment.constants.FSPAdminRole },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'admin/model-family', component: ModelFamilyComponent,
    data: { roles: environment.constants.FSPAdminRole },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'admin/model', component: ModelNumberComponent,
    data: { roles: environment.constants.FSPAdminRole },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'serial', component: SerialNumberComponent,
    data: { roles: environment.constants.FSPAdminRole },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'admin/model-family-mapping', component: ModelFamilyMappingComponent,
    data: { roles: environment.constants.FSPAdminRole },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'admin/market-family-mapping', component: MarketModelFamilyMappingComponent,
    data: { roles: environment.constants.FSPAdminRole },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'admin/model-serial-mapping', component: ModelSerialMappingComponent,
    data: { roles: environment.constants.FSPAdminRole },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'admin/modelfamily-spec-mapping', component: ModelfamilySpecMappingComponent,
    data: { roles: environment.constants.FSPAdminRole },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
    path: 'admin/file-upload', component: FileUploadComponent,
    data: { roles: environment.constants.FSPAdminRole },
    canActivate: [MsalGuard, RoleGuard]
  },
  {
   path: '',
   component: FluidSelectorComponent,
   pathMatch: 'full',
   ////canActivate: [MsalGuard]
  },
  {
    path: 'admin',
    component: FluidSelectorComponent,
    pathMatch: 'full',
    //canActivate: [MsalGuard]
   },
  {
    path: 'acessdenied',
    component: AccessdeniedComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
