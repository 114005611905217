import { AppError } from '../globalerrorhandler/AppError';
import { ForbiddenError } from '../globalerrorhandler/ForbiddenError';
import { UnAuthorizedError } from '../globalerrorhandler/UnauthorizedError';
import { BadRequestError } from '../globalerrorhandler/BadRequestError';
import { InternalServerError } from '../globalerrorhandler/InternalServerError';
import {NotificationService} from '../../Common-Utils/Services/notification.service';
import { Router } from '@angular/router';

/* UI error handler to handle exceptions */
export class UIErrorHandler {

  constructor() {
  }

  // Handle generic errors by passing the reference of toastr and router from the calling component
  handleError(error: AppError, toastr: NotificationService, router: Router) {
    if (error instanceof ForbiddenError) {
      toastr.showError(error.Message, 'FORBIDDEN ' + (error.ExceptionType) ? error.ExceptionType : '');
      router.navigate(['error/403']);
    } else if (error instanceof UnAuthorizedError) {
      toastr.showError(error.Message, 'UNAUTHORIZED ' + (error.ExceptionType) ? error.ExceptionType : '');
      router.navigate(['error/401']);
    } else if (error instanceof BadRequestError) {
      toastr.showError(error.Message, 'BAD REQUEST ' + (error.ExceptionType) ? error.ExceptionType : '');
    } else if (error instanceof InternalServerError) {
      toastr.showError(error.Message, 'INTERNAL SERVER ERROR ' + (error.ExceptionType) ? error.ExceptionType : '');
      // toastr.showError(
      //   (error.ExceptionType ? '<strong>Type: </strong>' + error.ExceptionType : '') +
      //   (error.Message ? ' <strong>Message: </strong>' + error.Message : ''), 'INTERNAL SERVER ERROR');
    } else {
      toastr.showError(error.Message, 'UNEXPECTED ERROR ' + (error.ExceptionType) ? error.ExceptionType : '');
    }
  }
}
