<header id="header" class="up-delay up">
    <div class="row wide-gutters-xl normal-gutters-lg narrow-gutters">
        <div class="col">
            <div class="homepage-link">
                <a href="/" class="logo">
                    <img src="https://www.dana.com/globalassets/dana_logo.png" alt="Dana | Home">
                </a>
                <div class="topnav">
                  <a href="{{fmpUrl}}" target="_blank">Fluid Approval </a>
                  <a [routerLink]="fspUrl">Fluid Selector</a>                 
                  <a href="{{fmpSupportUrl}}" target="_blank">Support </a>
                  <div *ngIf="loggedIn">
                    <a [routerLink]="fmpAdminUrl">Admin</a>
                  </div>
                </div>
            </div>
        </div>
        <div class="col-auto align-self-center">
            <div class="navigation menu-wrapper" id="main-navigation" aria-label="Main Navigation">
                <ul class="moncur-dropdown sf-menu">

                    <li class=""><a href="#">&nbsp;</a></li>


                </ul>
            </div>

        </div>
        <div class="col-auto align-self-center">
            <div class="navigation sub-links">
            </div>
        </div>
        <div class="col-auto align-self-center search-column">
        </div>
        <div class="mobile-toggle-menu align-self-center">
            <div class="nav-toggle" id="nav-icon1">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="col-auto align-self-center headerLabel">
            <div *ngIf="!loggedIn">
                <button type="button" title ="For Dana User Only" (click)="login()" class="btn btn-default btn-sm text-white btn-primary">
                     Log in
                </button>
            </div>
            <div *ngIf="loggedIn" class="username">
                <br>
                 <label class="form-check-label" for="autoSizingCheck">Welcome {{userName}} </label>
                <br>
                <button type="button" (click)="logout()" class="btn btn-default btn-sm text-white ">
                     <fa-icon class="form-check-label"  [icon]="faSignOutAlt"  style="color: #fff;"></fa-icon>
                     Log out
                </button>

                <p class="display-block">&nbsp;</p>
            </div>

        </div>
    </div>


    <form action="/search/" method="get">
        <div class="input-group" id="search-bar">
            <label class="sr-only" for="site-search">Search History, market, locations... </label>
            <input type="text" class="form-control" name="q" id="site-search" placeholder="Search..."
                title="Site Search">
        </div>
        <div class="input-group-append">
            <button id="SearchButton" class="btn btn-secondary" type="submit">
                <i class="fal fa-search"><span class="sr-only">Search this website</span></i>
            </button>
        </div>
    </form>
</header>
