<app-banner [headerstring]="headerstring"></app-banner>
<div class="banner-small banner-md banner-xl-large ">
  <div class="container">
    <div class="row wide-gutters-xl">
      <div class="col-md-3">
        <app-sidebar [data]="fileupload"></app-sidebar>

        <div class="d-none d-md-block">
        </div>
      </div>
      <div class="col-md-9">        
        <div class="divide-10 divide-md-0"></div>
        <h3 class="h1 headline-large">File Upload</h3>
        <div class="form-row">
          <div class="form-group col-lg-9 col-md-12 col-sm-12">
            <label>Select the type of data to be imported: </label>

            <div class="check-box-search ">
              <div class="form-check">
                <input class="form-check-input" type="radio" value="2" [(ngModel)]="_fileType" name="_fileType"
                (change)="onFileTypeSelectionChange('2')" id="modelSerial">
                <label class="form-check-label fileupload" for="modelSerial">Serial n<span>&#176;</span> - Model Mapping</label>
                <a class="link" style="cursor:pointer" title="Click here to download template file to upload" 
                (click)="fnDownloadTemplate('./assets/Templates/SerialNumber_Model_Mapping.xlsx','SerialNumber_Model_Template')"> 
                <fa-icon [icon]="faDownload"><span class="sr-only">Download</span></fa-icon> </a>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" value="1" [(ngModel)]="_fileType" name="_fileType"
                      (change)="onFileTypeSelectionChange('1')" id="modelFamilyModel">
                      <label class="form-check-label fileupload" for="modelFamilyModel">Model - Model Family Mapping</label>
                      <a class="link" style="cursor:pointer" title="Click here to download template file to upload"  
                      (click)="fnDownloadTemplate('./assets/Templates/Model_ModelFamily_Mapping.xlsx','Model_ModelFamily_Template')"> 
                      <fa-icon [icon]="faDownload"><span class="sr-only">Download</span></fa-icon> </a>
                      
                     
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" value="3" [(ngModel)]="_fileType" name="_fileType"
                (change)="onFileTypeSelectionChange('3')" id="modelFamilySpecification">
                <label class="form-check-label fileupload" for="modelFamilySpecification">Model Family - Specification Mapping</label>
                <a class="link" style="cursor:pointer" title="Click here to download template file to upload" 
                (click)="fnDownloadTemplate('./assets/Templates/ModelFamily_Specification_Mapping.xlsx','ModelFamily_Specification_Template')"> 
                <fa-icon [icon]="faDownload"><span class="sr-only">Download</span></fa-icon> </a>
              </div>

            </div>
          </div>
          <div class="col-lg-9 col-md-12 col-sm-12">
            <div class="input-group">
              <input type="file" #fileInput class="form-control button-height" placeholder="Browse file here..." />
              <div class="input-group-append">
                <button class="btn btn-info" type="button"  [attr.title]="!_fileTypeSelected ? 'Please Select type to get imported' : 'Import Files'" [ngStyle]="{'cursor': (!_fileTypeSelected? 'not-allowed':'pointer'),'background': (!_fileTypeSelected? '#A9A9A9':'#007bbf')}" [disabled]="!_fileTypeSelected"  (click)="fnUploadButtonClick();">
                  <fa-icon [icon]="faUpload"><span class="sr-only">Search</span></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
<ngx-ui-loader></ngx-ui-loader>