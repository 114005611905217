import { Component, OnInit } from '@angular/core';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  faFacebook=faFacebook;
  faTwitter=faTwitter;
  faLinkedinIn=faLinkedinIn;
  faYoutube=faYoutube;
  currentYear = new Date().getUTCFullYear(); 
 
  constructor() { }

  ngOnInit(): void {
  }

}
