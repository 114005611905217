<app-banner [headerstring]="headerstring"></app-banner>
<div class="banner banner-lg-large">
    <div class="container">
        <div class="row wide-gutters-lg">           
            <div class="col-md-12">
                <h2 class="alert-danger">{{headerstring}}</h2>
                {{accessDeniedMessage}}
                <p [innerHTML]="backtoHomeMessage"></p>           
        </div>
    </div>
    </div>
</div>