import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridModule } from 'ag-grid-angular';
import { ButtonRendererComponent } from './button-renderer/button-renderer.component';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FluidSelectorComponent } from './fluid-selector/fluid-selector.component';
import { ModelFamilyComponent } from './model-family/model-family.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ModelNumberComponent } from './model-number/model-number.component';
import { SerialNumberComponent } from './serial-number/serial-number.component';
import { ModelFamilyMappingComponent } from './model-family-mapping/model-family-mapping.component';
import { MarketComponent } from './market/market.component';
import { SearchResultsComponent } from './fluid-selector/search-results/search-results.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MarketModelFamilyMappingComponent } from './market-model-family-mapping/market-model-family-mapping.component';
import { ModelSerialMappingComponent } from './model-serial-mapping/model-serial-mapping.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule} from '@angular/material/slider';
import { ModelfamilySpecMappingComponent } from './modelfamily-spec-mapping/modelfamily-spec-mapping.component';

import { environment } from 'src/environments/environment';  
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http'
import { MsalModule, MsalInterceptor } from '@azure/msal-angular'; 
import { MsaluserService } from './msaluser.service'; 
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ButtonRendererDeleteComponent } from './Common-Utils/components/button-renderer-delete/button-renderer-delete.component';
import { BannerComponent } from './banner/banner.component';
import { ButtonRendererEditComponent } from './Common-Utils/components/button-renderer-edit/button-renderer-edit.component';
import { ToastrModule } from 'ngx-toastr';  
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MatTableModule } from '@angular/material/table';
import { CommonDirectiveDirective } from './Common-Utils/directives/common-directive.directive' 
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AccessdeniedComponent } from './accessdenied/accessdenied.component';
import { InputSearchComponent } from './fluid-selector/input-search/input-search.component';
import { LastModifiedComponent } from './Common-Utils/components/last-modified/last-modified.component';
export const protectedResourceMap: any =  
  [  
    [environment.baseUrl, environment.scopeUri ]  , ['https://graph.microsoft.com/beta/', ['user.read']]
  ];

@NgModule({
  declarations: [
    AppComponent,  
    HeaderComponent,
    FooterComponent,
    FluidSelectorComponent,
    ModelFamilyComponent,
    SidebarComponent,
    ModelNumberComponent,
    SerialNumberComponent,
    ButtonRendererComponent, 
    ModelFamilyMappingComponent,
    MarketComponent,
    SearchResultsComponent,
    MarketModelFamilyMappingComponent, 
    ModelSerialMappingComponent,
    ModelfamilySpecMappingComponent,
    ButtonRendererDeleteComponent,
    BannerComponent,
    ButtonRendererEditComponent,
    FileUploadComponent,
    CommonDirectiveDirective,
    AccessdeniedComponent,
    InputSearchComponent,
    LastModifiedComponent,
   

  ],

  imports: [
    MsalModule.forRoot({
      auth: {
        clientId: environment.uiClienId,
        authority: "https://login.microsoftonline.com/" + environment.tenantId, 
        // knownAuthorities: [],
        redirectUri: environment.redirectUri,
        //validateAuthority : false
        postLogoutRedirectUri: environment.postLogoutRedirectUri,
        navigateToLoginRequestUrl: true
      
      },
      cache: {	          
        cacheLocation: 'localStorage',	          
        storeAuthStateInCookie: false, // Set to true for Internet Explorer 11	        
      },
    },
    {	        
      popUp: false,	        
      consentScopes: [	          
        "user.read",	          
        "openid",	          
        "profile",	          
        environment.scopeUri	        
      ],	        	 
      unprotectedResources: [],
      protectedResourceMap: [
        ['https://graph.microsoft.com/v1.0/me', ['user.read']]
      ],       
      extraQueryParameters: {}	      
    },
    
    ),
    BrowserModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,FormsModule,ReactiveFormsModule,
    AgGridModule.withComponents([ButtonRendererComponent,ButtonRendererDeleteComponent]),
    NgbModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatFormFieldModule,
    MatTableModule,
    MatInputModule,ToastrModule.forRoot({positionClass: 'toast-bottom-full-width'}),
    SweetAlert2Module.forRoot(),
    NgxUiLoaderModule
  ],
  providers: [  
    HttpClient,  
    MsaluserService,  
    {  
      provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true  
    }  
  ], 
  bootstrap: [AppComponent]
})
export class AppModule { }
