import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap, startWith } from 'rxjs/operators';
import { ButtonRendererDeleteComponent } from '../Common-Utils/components/button-renderer-delete/button-renderer-delete.component'
import { CommonService } from '../Common-Utils/Services/common.service';
import { FormControl, FormBuilder, FormGroup, Validators ,ValidationErrors, ValidatorFn,AbstractControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../Common-Utils/Services/notification.service'
import { MsaluserService } from '../msaluser.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Model } from '../Common-Utils/datamodels/Model';
import { UIErrorHandler } from '../Common-Utils/globalerrorhandler/UIErrorHandler';
import { AppError } from '../Common-Utils/globalerrorhandler/AppError';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-model-family-mapping',
  templateUrl: './model-family-mapping.component.html',
  styleUrls: ['./model-family-mapping.component.css']
})
export class ModelFamilyMappingComponent implements OnInit {
  faPlusSquare = faPlusSquare;
  form: FormGroup;
  //For autocomplete
  myControl: FormControl = new FormControl();
  filteredOptions: Observable<Model[]>;
  searchFilteredOptions: Observable<string[]>;
  model_family = [];
  model_number = [];
  model_number_search = [];
  headerstring: string = environment.bannerTexts.ModelFamilyModelMappingText
  showGrid: boolean = false;
  faSearch = faSearch;
  frameworkComponents: any;
  rowData: any;
  lastUpdateds:any;
  submitted = false;
  export:boolean=false;
  searchForm: FormGroup;
  model: any = {
    selectedModelFamily: '',
    selectedModel: ''
  };
  search_form_valid: boolean = true;
  searchText = '';
  searchText1 = '';
  modelfamilyMaping: string
  public _genericErrorHandler = null;
  clearButtonShow:boolean = false;

  constructor(
    private notifyService: NotificationService,
    private formBuilder: FormBuilder,
    private _commonService: CommonService,
    private msalService: MsaluserService,
    private router: Router) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererDeleteComponent,
    }
  }
  columnDefs = [

    { headerName: 'Model Family', field: 'ModelFamily', resizable: true },
    { headerName: 'Model Number', field: 'ModelName', resizable: true, minWidth: 350, flex: 2 },

    {
      headerName: 'Action',
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.fnBindDeleteMapping.bind(this),
        label: 'Click 1'
      }
    },
  ];


  ngOnInit(): void {
    this.modelfamilyMaping = "modelfamilyMaping";
    this._genericErrorHandler = new UIErrorHandler();
    this.fnBindMappingForm();
    this.fnBindSearchForm();
    this.fnBindModelFamily();
   
   // this.fnBindModelSearch();
    //calling search result grid--as per the new changes[Binding top 100 rows oninit]
    this.fnBindSearchGridResultAPI('');
    this.fnBindLastUpdatedByModel();
  }

  fnBindMappingForm() {
    this.form = this.formBuilder.group({
      selectedModelFamily: ['', Validators.required],
      selectedModel: ['', Validators.required]
    });
  }
  
  /*Fn to fetch Last ModifiedBy and ModifiedDate of model */
  fnBindLastUpdatedByModel() {
    //Binding Last CreatedBy and CreatedDate from API  
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetLastModifiedModel?_tableName=ModelFamilyModel', 'get', null).subscribe(
      res => {
        if (res != null) {          
          this.lastUpdateds = res["TBL_Result"]; 
        }
      }, (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      });
  }

  fnBindSearchForm() {
    this.searchForm = this.formBuilder.group({
      'search_model_family':[''] ,
      'search_model_number':[''] 
    })
  }

  get f() { return this.searchForm.controls; }
  get m() { return this.form.controls; }


  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      console.log(this.form.invalid)
      return;
    }
    console.log('form value', this.form.value);
    const addMapData: any = {
      'ModelId': this.model.selectedModel, // Number(this.form.controls["selectedModel"].value["ModelID"]),
      'ModelFamilyId': Number(this.form.controls["selectedModelFamily"].value),
      'CreatedBy': this.msalService.getCurrentUserInfo()

    };
    console.log('aa', addMapData);
    //return;
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/MapModelToModelFamily', 'post', addMapData).subscribe(res => {
      console.log('Model Family - Model Mapping added successfully!', res);
      if (res) {
        if (res == environment.constants.Success) {
          this.router.navigateByUrl('admin/model-family-mapping');
          this.notifyService.showSuccess(environment.messageParams.msgModelFamilyModelMappingSuccess, "Success");
         
          this.form.controls['selectedModelFamily'].setValue("", {onlySelf: true});
          this.form.controls['selectedModel'].setValue("", {onlySelf: true});
          this.searchText='';
          this.fnBindModel();
          this.fnBindLastUpdatedByModel();
          this.submitted = false;
        } else {
          this.notifyService.showError(environment.messageParams.msgSaveError, "Error")
        }
      }
      else {
        this.notifyService.showError(environment.messageParams.msgSaveError, "Error")
      }

    },
    (error: AppError) => {
      this._genericErrorHandler.handleError(error, this.notifyService, this.router);
    }
    )

  }
  fnBindDeleteMapping(e) {
    //Getting ID to pass to Delete API 
    let _mfid = e.rowData.MapID;

    this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/DeleteModelFamilyModelL?id=' + _mfid, 'post', null).subscribe(res => {

      console.log('res-del', res);
      if (res) {
        if (res == environment.constants.Success) {
          this.notifyService.showSuccess(environment.messageParams.msgModelFamilyMappingDeleted, "Success")
          //Reload the grid
          this.searchFunc()
        } else { this.notifyService.showError(environment.messageParams.msgDeleteError, "Error") }
        this.fnBindSearchGridResultAPI('');
      }
      else {
        this.notifyService.showError(environment.messageParams.msgDeleteError, "Error")
      }
    },
    (error: AppError) => {
      this._genericErrorHandler.handleError(error, this.notifyService, this.router);
    })
  }

  onSelectModelNumber(event: any) {
    console.log('2', event.option.value['ModelID']);
    this.model.selectedModel = event.option.value['ModelID'];

  }
  searchFunc() {

    if (this.searchForm.value.search_model_number == "" && this.searchForm.value.search_model_family == "") {
      this.search_form_valid = false
      return;
    }
    this.clearButtonShow=true
    this.export=false;
    this.fnBindSearchGridResultAPI(this.searchForm.value)
  }


  fnBindModelFamily() {
    //Binding ModelFamily Dropdown from API 

    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetModelFamily', 'get', null).subscribe(
      res => {
        console.log('Generic', res);
        console.log('tbl', res["TBL_Result"]);
        if (res != null) {
          this.model_family = res["TBL_Result"];
          // this.model_family=JSON.parse(res.toLocaleString()).TBL_Result;
          console.log('model-family', this.model_family);
        }
      },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      }
    );

  }
  fnBindModel() {
    //Binding ModelNumber Dropdown from API

    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetModel?isUnmapped=true&searchtext=' + this.searchText, 'get', null).subscribe(
      res => {

        if (res != null) {
          this.model_number = res["TBL_Result"];
          //this.model_number = JSON.parse(res.toLocaleString()).TBL_Result; 
          console.log(this.model_number);
          //Binding for autocomplete for modelNumber
          //this.filteredOptions=this.myControl.valueChanges.pipe(startWith(''),map(val => this.filterModelNum(val)))  ; 
          this.filteredOptions = this.form.controls['selectedModel'].valueChanges.pipe(startWith(''),
            // map(value => typeof value === 'number' ? value : value.ModelID),
            map(x => typeof x === 'string' ? x : x.ModelName),
            map(ModelName => ModelName ? this._filter(ModelName) : this.model_number.slice())

          );
        }
      },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      });

  }
  //Filteration for autocomplete of ModelNumber
  private filterModelNum(val: string): string[] {
    return this.model_number.map(x => x.ModelTag).filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  /*Fn to bind model number for search fileld */
  fnBindModelSearch() {
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetModel?searchtext='+this.searchText1, 'get', null).subscribe(
      res => {
        console.log("result of model number search field", res)
        if (res != null) {
          this.model_number_search = res["TBL_Result"];
          //Binding for autocomplete for modelNumber

          //this.searchFilteredOptions=this.searchControl.valueChanges.pipe(startWith(''),map(val => this.filterModelNumSearch(val)))  ;  
          this.searchFilteredOptions = this.searchForm.controls['search_model_number'].valueChanges.pipe(startWith(''), map(val => this.filterModelNumSearch(val)));
          console.log(this.searchFilteredOptions, "this.searchFilteredOptions")
        }
      },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      });
  }
  //Filteration for autocomplete of ModelNumber
  private filterModelNumSearch(val: string): string[] {
    return this.model_number_search.map(x => x.ModelName).filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  fnBindSearchGridResultAPI(_searchItems) {
    this.search_form_valid = true
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetModelFamilyModel?_modelnumber=' + (_searchItems.search_model_number==undefined?'':_searchItems.search_model_number) + '&_modelFamily=' + (_searchItems.search_model_family==undefined?'':_searchItems.search_model_family), 'get', null).subscribe(
      res => {
        if (res != null) {
          this.rowData = res["TBL_Result"];
          console.log('rowData', this.rowData);
          this.showGrid = true;
          console.log('griddata', this.rowData);
        }
      },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      }
    );
  }

  private _filter(name: string): Model[] {
    const filterValue = name.toLowerCase();

    return this.model_number.filter(option => option.ModelName.toLowerCase().indexOf(filterValue) === 0);
  }
  displayFn(z: Model): string {
    return z && z.ModelName ? z.ModelName : '';
  }

  refreshSearchGrid(){
    console.log("search clear")
    this.searchForm.controls['search_model_number'].setValue('');
    this.searchForm.controls['search_model_family'].setValue(null);
    this.fnBindSearchGridResultAPI('')
    this.clearButtonShow=false;
    this.export=false;
    
  }

  //key up event for mapping modelnumber textbox
  onKeyUp(event) {
    this.searchText = event.target.value;    
    if(this.searchText.length>0){
      this.fnBindModel(); 
    }
    else{
      this.model_number=[];
    }
      
  }
  


  onKeyUp1(event) {
    this.searchText1 = event.target.value;
    if (this.searchText1.length > 0) {
      this.fnBindModelSearch();    
    }
    else {
      this.model_number = [];
    }
  }
  exportAsXLSX(){
    this.export=true;
    const ModelFamilyModelList = [];
    this.rowData.forEach((item) => {
      ModelFamilyModelList.push({
          'Model Family': item.ModelFamily,
          'Model Number': item.ModelName,
        });
      });
    this.msalService.exportAsExcelFile(ModelFamilyModelList, 'ModelFamily-Model');
  }
}
