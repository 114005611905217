import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '../Common-Utils/Services/common.service';
import { AppError } from '../Common-Utils/globalerrorhandler/AppError';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Model } from '../Common-Utils/datamodels/Model';
import { environment } from 'src/environments/environment';
import { UIErrorHandler } from '../Common-Utils/globalerrorhandler/UIErrorHandler';
import { NotificationService } from '../Common-Utils/Services/notification.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var $: any;

@Component({
  selector: 'app-fluid-selector',
  templateUrl: './fluid-selector.component.html',
  styleUrls: ['./fluid-selector.component.css'],
})
export class FluidSelectorComponent implements OnInit {
  searchResult: any = '';
  isReset = true;
  resultedFluids: any = [];
  headerstring: string = 'Search';
  searchType: number = 0;

  constructor(
    private httpClient: HttpClient,

    private _commonService: CommonService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router
  ) {}

  ngOnInit() {}

  getSearchResponse(event) {
    console.log(event);
    if (event === 'reset') {
      this.searchResult = [];
      this.isReset = true;
    } else if (event === 'nodata') {
      this.searchResult = [];
      this.isReset = false;
    } else {
      this.searchResult = event;
      this.isReset = false;
      // if (event && event.length > 0) {
      //   $('#inputSearch').collapse('hide');
      // }
    }
  }

  handleSearchTypeChange(newSearchType: number) {
    console.log(newSearchType);
    this.searchType = +newSearchType;
  }

  ngOnChanges() {
    console.log('res', this.resultedFluids);
    if (this.resultedFluids) {
    }
  }
} //close of export
