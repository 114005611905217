<app-banner [headerstring]="headerstring"></app-banner>
<div class="banner-small banner-md banner-xl-large">
  <div class="container">
    <div class="row wide-gutters-xl">
      <div class="col-md-3">
        <app-sidebar [data]="serial"></app-sidebar>

        <div class="d-none d-md-block">
        </div>
      </div>
      <div class="col-md-9">
        <div class="divide-10 divide-md-0"></div>
        <h2 class="h1 headline-large">Serial Number</h2>
        <div class=" bottom">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>Serial Number </label>
                <input type="text" formControlName="serialNumber" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.serialNumber.errors }" />
                <div *ngIf="submitted && f.serialNumber.errors" class="invalid-feedback">
                  <div *ngIf="f.serialNumber.errors.required">Serial Number required</div>
                </div>
              </div>
              <div class="form-group col-md-4">
                <label>Description</label>
                <textarea formControlName="description" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.description.errors }" rows="1"></textarea>
                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                  <div *ngIf="f.description.errors.required">Description is required</div>
                </div>
              </div>
              </div>
              <div class="form-row">
              <div class="form-group col-md-4">
                <label > Production Date</label>
                <input type="text" formControlName="production_date" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.production_date.errors }" />
                <div *ngIf="submitted && f.production_date.errors" class="invalid-feedback">
                  <div *ngIf="f.production_date.errors.required">Production Date required</div>
                </div>
              </div>  
              <div class="m-top-em">
                <button type="submit" class="btn btn-info" *ngIf="isAddMode">Save</button>
                <button type="submit" class="btn btn-info" *ngIf="!isAddMode">Update</button>
                <button type="button" class="btn btn-info m-left-em" *ngIf="!isAddMode"
                  (click)="fnNewForm()">Cancel</button>

              </div>
            </div>

          </form>
        </div>
        <div class="m-top-2em">
          <div class="row">
            <div class="col-lg-4">
              <div class="input-group">
                <input [(ngModel)]="searchModel" class="form-control" placeholder="Search..." />
                <div class="input-group-append">
                  <button class="btn btn-info" type="button" (click)="searchFunc()">
                    <fa-icon [icon]="faSearch"><span class="sr-only">Search</span></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="banner-margin-medium-top banner-md-margin-top" *ngIf="showGrid">

          <ag-grid-angular  class="ag-theme-alpine vertical-scroll" pagination=true
            paginationPageSize=10 enableRangeSelection=true, [rowData]="rowData" [columnDefs]="columnDefs"
            [frameworkComponents]="frameworkComponents">

          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>