<footer id="footer" aria-label="Footer">
    <div class="footer-top banner-small banner-sm-medium banner-lg banner-xl-large">
        <div class="container">
            <div class="row wide-gutters d-none d-lg-block">
                <div class="col-lg-8 offset-lg-4">
                    <div class="text-md-right">
                        <div class="divide-50 divide-md-0"></div>
                            <ul class="footer-list--inline">
                                    <li><a href="https://www.dana.com/careers/">Careers <span class="sr-only"></span></a></li>
                                    <li><a href="https://danaincorporated.gcs-web.com/">Investors <span class="sr-only"></span></a></li>
                                    <li><a href="https://www.dana.com/newsroom/">Newsroom <span class="sr-only"></span></a></li>
                                    <li><a href="https://www.dana.com/suppliers/">Suppliers <span class="sr-only"></span></a></li>
                            </ul>
                        <div class="footer-break"></div>
                    </div>
                </div>     
            </div>
            <div class="row wide-gutters-lg">
                <div class="col-lg-4">
                    <div><div class="block-epi  footerresourceblock  ">

<span class="footer-title">2019 ANNUAL REPORT</span>
<div class="row narrow-gutters">
    <div class="col-xs-10 col-lg-8 col-xl-9 order-xs-last">
        <p>For 115 years, Dana has led the charge. We have proven time and again that we not only evolve in tandem with the industry, but also remain a step ahead. We continually build upon our foundation of success to support new applications as they emerge, and partner with customers as they develop tomorrow’s vehicles.</p>

        <div class="d-lg-none">
            <a class="link" href="https://www.dana.com/globalassets/dana-2019annualreport_web.pdf">Download<span class="sr-only">Annual Report</span></a>
        </div>
    </div>
    <div class="col-xs-2 col-lg-4 col-xl-3 order-md-first">
        <a href="https://www.dana.com/globalassets/dana-2019annualreport_web.pdf" target="_blank" class="annual-report">
            

        <img src="https://www.dana.com/globalassets/2019danaannualreport.png">


            <div class="d-none d-lg-inline-block">
                <div class="divide-5"></div>
                <span class="small d-block">Download<span class="sr-only">Annual Report</span></span>
                <span class="download-arrow"><img src="https://www.dana.com/Static/img/arrow-download-white.svg" class="arrow-svg download" alt="arrow download"></span>
            </div>
        </a>
    </div>
</div>



</div></div>
                </div>
                <div class="col-sm-6 col-md-12 col-lg-8 d-none d-sm-block">
                    <div class="divide-50 divide-lg-0"></div>
                    <div class="row">





                                                        <div class="col-md-3">
                                    <span class="footer-title">
                                        <a href="https://www.dana.com/company/" title="Company">Company</a>
                                    </span>

                                            <ul class="footer-list d-sm-none d-md-block">
                                                                                <li>
                                    <a href="https://www.dana.com/company/history/">History</a>
                                    
                                </li>
                                <li>
                                    <a href="https://www.dana.com/company/vision-mission-values/">Mission and Vision</a>
                                    
                                </li>
                                <li>
                                    <a href="https://www.dana.com/company/leadership/">Leadership</a>
                                    
                                </li>
                                <li>
                                    <a href="https://www.dana.com/company/innovation-technology/">Innovation and Technology</a>
                                    
                                </li>
                                <li>
                                    <a href="https://www.dana.com/company/global-technology-centers/">Global Technology Centers</a>
                                    
                                </li>
                                <li>
                                    <a href="https://www.dana.com/company/brands/">Brands</a>
                                    
                                </li>
                                <li>
                                    <a href="https://www.dana.com/company/sustainability-and-social-responsibility/">Sustainability and Social Responsibility</a>
                                    
                                </li>

                                            </ul>
                                </div>
                                <div class="col-md-3">
                                    <span class="footer-title">
                                        <a href="https://www.dana.com/markets/" title="Markets">Markets</a>
                                    </span>

                                            <ul class="footer-list d-sm-none d-md-block">
                                                                                <li>
                                    <a href="https://www.dana.com/markets/light-vehicle/">Light Vehicle</a>
                                    
                                </li>
                                <li>
                                    <a href="https://www.dana.com/markets/commercial-vehicle/">Commercial Vehicle</a>
                                    
                                </li>
                                <li>
                                    <a href="https://www.dana.com/markets/off-highway/">Off-Highway</a>
                                    
                                </li>
                                <li>
                                    <a href="https://www.dana.com/markets/aftermarket/">Aftermarket</a>
                                    
                                </li>

                                            </ul>
                                </div>
                                <div class="col-md-3">
                                    <span class="footer-title">
                                        <a href="https://www.dana.com/e-mobility/" title="e-Mobility">e-Mobility</a>
                                    </span>

                                </div>
                                <div class="col-md-3">
                                    <span class="footer-title">
                                        <a href="https://www.dana.com/contact/" title="Contact">Contact</a>
                                    </span>

                                            <ul class="footer-list d-sm-none d-md-block">
                                                                                <li>
                                    <a href="https://www.dana.com/contact/locations/">Locations</a>
                                    
                                </li>

                                            </ul>
                                </div>


                    </div>
                    
                </div>
                <div class="col-sm-6 col-md-12 d-none d-sm-block d-lg-none">
                    <div class="divide-50"></div>
                        <div class="footer-break d-none d-md-block"></div>
                        <ul class="footer-list--inline sup-nav">
                                <li><a href="https://www.dana.com/careers/">Careers <span class="sr-only"></span></a></li>
                                <li><a href="https://danaincorporated.gcs-web.com/">Investors <span class="sr-only"></span></a></li>
                                <li><a href="https://www.dana.com/newsroom/">Newsroom <span class="sr-only"></span></a></li>
                                <li><a href="https://www.dana.com/suppliers/">Suppliers <span class="sr-only"></span></a></li>
                        </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom banner-medium">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-7 text-md-right order-md-last">


                        <ul class="footer-list--inline terms">
                                <li><a href="https://www.dana.com/terms-of-use/">Terms of Use <span class="sr-only"></span></a></li>
                                <li><a href="https://www.dana.com/privacy-notice/">Privacy Notice <span class="sr-only"></span></a></li>
                                <li><a href="https://www.dana.com/sitemap/">Sitemap <span class="sr-only">Sitemap</span></a></li>
                        </ul>
                                            <ul class="footer-list--inline social">
                                               
                                                
                                                
                                <li><a href="https://www.linkedin.com/company/dana-incorporated" target="_blank"><fa-icon [icon]="faLinkedinIn"><span class="sr-only">LinkedIn</span></fa-icon></a></li>
                                <li><a href="https://twitter.com/DanaInc_" target="_blank"><fa-icon [icon]="faTwitter"><span class="sr-only">Twitter</span></fa-icon></a></li>
                                <li><a href="https://www.youtube.com/user/DanaHoldingCorp" target="_blank"><fa-icon [icon]="faYoutube"><span class="sr-only">Youtube</span></fa-icon></a></li>
                                <li><a href="https://www.facebook.com/DanaIncorporated/" target="_blank"><fa-icon [icon]="faFacebook"><span class="sr-only">Facebook</span></fa-icon></a></li>
                               
                            </ul>
                    </div>
                <div class="col-md-5 order-md-first">
                    <div class="divide-xs-20 divide-md-0"></div>
                    <span class="copyright"> Copyright ©  {{currentYear}} Dana Limited. All Rights Reserved.</span>
                </div>
            </div>
        </div>
    </div>
</footer>
