import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-last-modified',
  templateUrl: './last-modified.component.html',
  styleUrls: ['./last-modified.component.css']
})
export class LastModifiedComponent implements OnInit {
  @Input() lastUpdateds: any;
  constructor() { }

  ngOnInit(): void {
  }

}
