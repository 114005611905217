<app-banner [headerstring]="headerstring"></app-banner>
<div class="banner-small banner-md banner-xl-large">
  <div class="container">
    <div class="row wide-gutters-xl">
      <div class="col-md-3">
        <app-sidebar [data]="modelfamilyMaping"></app-sidebar>
        <div class="d-none d-md-block"></div>
      </div>
      <div class="col-md-9">
        <div class="divide-10 divide-md-0"></div>
        <h3 class="h1 headline-large">Add Model - Model Family Mapping</h3>
        <div class=" ">
          <form [formGroup]="form" (ngSubmit)=onSubmit()>
            <div class="form-row">
              <div class="form-group  col-lg-4 col-md-4 col-sm-12">
                <label>Model Family</label>
                <select *ngIf="model_family" formControlName="selectedModelFamily" required class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && m.selectedModelFamily.errors }">
                  <option value="" selected>Select Model Family</option>
                  <option *ngFor="let modelFamily of model_family" value={{modelFamily.ModelFamilyID}}>
                    {{modelFamily.ModelFamilyName}}</option>
                </select>
                <div *ngIf="submitted && m.selectedModelFamily.errors" class="invalid-feedback">
                  <div *ngIf="m.selectedModelFamily.errors.required">Model Family  is required</div>
              </div>
              </div>
              <div class="form-group col-lg-4 col-md-4  col-sm-12  ">
                <label>Model Number</label>
                <!-- auto complete from Model Number -->
                <mat-form-field class="full-width marigin_top_minus_5px matform-field-full-width" appearance="outline">

                  <input matInput type="text" class="full-width" formControlName="selectedModel"
                   [matAutocomplete]="auto" 
                    required  [ngClass]="{ 'is-invalid': submitted && m.selectedModel.errors }" (keyup)="onKeyUp($event)">
                                        
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" panelWidth="270px"
                  (optionSelected)='onSelectModelNumber($event)' [displayWith]="displayFn">
                     <mat-option *ngFor="let option of filteredOptions |async" [value]="option">
                      {{option.ModelName}}
                    </mat-option>
                  </mat-autocomplete>   
                  <mat-error *ngIf="submitted && m.selectedModel.errors">
                    <span *ngIf="m.selectedModel.errors">Model is required.</span>
                    
                  </mat-error>                        
                </mat-form-field>

                <div *ngIf="submitted && m.selectedModelFamily.errors" class="invalid-feedback">
                  <div *ngIf="m.selectedModelFamily.errors.required">Model Family  is required</div>
              </div>
              </div>
              <div class="m-top-2em col-lg-4 col-md-4  col-sm-12 alignMiddle">
                <button type="submit" title="Add Model-Model Family" class="btn btn-info fullwidth-nomargin ">
                  <fa-icon [icon]="faPlusSquare"></fa-icon>  Add</button>
              </div>
            </div>
          </form>
        </div>
        <h3 class="h1 headline-large m-search-top-1em">Search </h3>
        <div class="">
         
          <form name="form" [formGroup]="searchForm" (ngSubmit)="searchFunc()" novalidate>
            <div class="form-row">
              <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label>Model Family</label>
                <input id="search_model_family" formControlName="search_model_family" type="text"
                  name="search_model_family" class="form-control" required />
                  <span *ngIf="!search_form_valid && searchForm.controls.search_model_family.untouched && !export" class="has-error">
                    Please enter Model Family or Model 
                  </span>
                 
              </div>
              <div class="form-group col-lg-6 col-md-6 col-sm-12 ">
                <label>Model Number</label>
                <!-- auto complete from Model Number -->
                <mat-form-field class="full-width marigin_top_minus_5px mat_field_width matform-field-full-width" appearance="outline">
                <input  matInput   type="text" class="full-width" formControlName="search_model_number" [matAutocomplete]="auto1"
                    required (keyup)="onKeyUp1($event)">                                       
                  <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" panelWidth="270px">
                      <mat-option *ngFor="let model of searchFilteredOptions |async" [value]="model">
                             {{model}}
                    </mat-option>
                  </mat-autocomplete>   
                             
                </mat-form-field>
              </div>
             
              <div class="form-group col-lg-4 col-md-4 col-sm-12 ">
                <button type="submit" class="btn btn-info fullwidth-nomargin"  
                [attr.title]="(  (!searchForm.get('search_model_number').dirty || searchForm.get('search_model_number').invalid )  &&               
                (!searchForm.get('search_model_family').dirty || searchForm.get('search_model_family').invalid)) ? 'Please Select Model or Model Family' : 'Search Model or Model Family'"
                [ngStyle]="{'cursor': (((!searchForm.get('search_model_number').dirty || searchForm.get('search_model_number').invalid )  &&               
                (!searchForm.get('search_model_family').dirty || searchForm.get('search_model_family').invalid))? 'not-allowed':'pointer'),
                'background': (((!searchForm.get('search_model_number').dirty || searchForm.get('search_model_number').invalid )  &&               
                (!searchForm.get('search_model_family').dirty || searchForm.get('search_model_family').invalid))? '#A9A9A9':'#007bbf')}"
                 [disabled]=" 
                (!searchForm.get('search_model_number').dirty || searchForm.get('search_model_number').invalid )  &&               
                (!searchForm.get('search_model_family').dirty || searchForm.get('search_model_family').invalid)
                 ">Search</button>
              </div>
              <div class="form-group col-lg-4 col-md-4 col-sm-12 ">
                 <button class="btn btn-info fullwidth-nomargin" title="Refresh Text" (click)="refreshSearchGrid()" type="button"> Clear</button>
              </div>
              <div class="form-group col-lg-4 col-md-4 col-sm-12">
                <button title="Export" class="btn btn-info fullwidth-nomargin" (click)="exportAsXLSX()">Export
                </button>
              </div>
            </div>

          </form>
         
        </div>
        <app-last-modified [lastUpdateds]="lastUpdateds"></app-last-modified>
        <div class="banner-margin-medium-top" *ngIf="showGrid">
          <ag-grid-angular  class="ag-theme-alpine vertical-scroll" pagination=true
            paginationPageSize=10 enableRangeSelection=true, [rowData]="rowData" [columnDefs]="columnDefs"
            [frameworkComponents]="frameworkComponents">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>