import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private _msalService: MsalService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._msalService.getAccount()
    if (currentUser) {
      const userToken = this._msalService.getAccount().idToken as any;

      if (userToken) {
        // check if route is restricted by role
        if (route.data.roles) {
          const allowedRoles = route.data["roles"];
          if (userToken.roles) {
            const matchingRoles = userToken.roles.filter(x => allowedRoles.includes(x));
            // role not authorised so redirect to home page
            if (matchingRoles.length > 0) {
              return true;
            }
          }
        }
        // role not authorised so redirect to error page
        this.router.navigate(['/acessdenied']);
        return false;
      }
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

}
