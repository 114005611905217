<app-banner [headerstring]="headerstring"></app-banner>
<div class="banner-small banner-md banner-xl-large">
  <div class="container">
    <div class="row wide-gutters-xl">
      <div class="col-md-3">
        <app-sidebar [data]="modelfamilySpecMaping"></app-sidebar>
        <div class="d-none d-md-block"></div>
      </div>

      <div class="col-md-9">
        <div class="divide-10 divide-md-0"></div>
        <h3 class="h1 headline-large">Add Model Family – Specification Mapping</h3>
        <div class=" ">
          <form [formGroup]="form" (ngSubmit)=onSubmit()>
            <div class="form-row">
              <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label>Model Family</label>
                <select *ngIf="model_family" formControlName="ModelFamilyId" required class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.ModelFamilyId.errors }">
                  <option value="" selected>Select ModelFamily</option>
                  <option *ngFor="let modelFamily of model_family" value={{modelFamily.ModelFamilyID}}>
                    {{modelFamily.ModelFamilyName}}</option>
                </select>
                <div *ngIf="submitted && f.ModelFamilyId.errors" class="invalid-feedback">
                  <div *ngIf="f.ModelFamilyId.errors.required">Model Family is required</div>
                </div>
              </div>
              <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label>Specification Internal Id</label>
                <input id="SpecificationId" type="text" formControlName="SpecificationId" class="form-control" required
                  [ngClass]="{ 'is-invalid': submitted && f.SpecificationId.errors }" />
                <div *ngIf="submitted && f.SpecificationId.errors" class="invalid-feedback">
                  <div *ngIf="f.SpecificationId.errors.required">Specification is required</div>
                  <div *ngIf="f.SpecificationId.errors.pattern">Enter valid Specification</div>
                </div>
              </div>

              <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label> Priority</label>
                <input id="priority" type="number" min="1" formControlName="Priority" class="form-control" required
                  [ngClass]="{ 'is-invalid': submitted && f.Priority.errors }" />
                <div *ngIf="submitted && f.Priority.errors" class="invalid-feedback">
                  <div *ngIf="f.Priority.errors.required">Priority is required</div>
                </div>
              </div>
              <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label> Additional Notes</label>
                <input id="additionalNotes" type="text" min="1" formControlName="AdditionalNotes" class="form-control" />
              </div>

              <div class="form-group col-lg-8 col-md-6 col-sm-12">
                <label>Minimum Prevailing Ambient Temperature</label>
                <mat-slider class="example-margin" formControlName="Temperature" [max]="max" [min]="min" [step]="step"
                  [displayWith]="formatLabel" [thumbLabel]="thumbLabel" [tickInterval]="getSliderTickInterval()"
                  [vertical]="vertical">
                </mat-slider>
              </div>
              <div class="m-top-2em col-lg-4 col-md-12 col-sm-12">
                <button type="submit" class="btn btn-info fullwidth-nomargin" title="Add Model Family - Specification">
                  <fa-icon [icon]="faPlusSquare"></fa-icon>  Add</button>
              </div>
            </div>
          </form>
        </div>
        <hr />
        <h3 class="h1 headline-large m-search-top-1em ">Search </h3>
     
          <form name="form" (ngSubmit)="searchFunc()" #sf="ngForm" novalidate>

            <div class="form-row">
              <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label>Model Family</label>
                <input [(ngModel)]="modelSearch.model_family_search" name="model_family_search"
                  #model_family_search="ngModel" required class="form-control" placeholder="Search..." />
              </div>
              <div class="form-group col-lg-6 col-md-6 col-sm-12">
                <label>Specification Internal Id</label>
                <input #specificationSearch="ngModel" type="text" name="specificationSearch" class="form-control"
                  required [(ngModel)]="modelSearch.specificationSearch" />
                <span *ngIf="!search_form_valid && !export" class="has-error">
                  Please enter Specification or Model
                </span>
              </div>
              <div class="form-group col-lg-4 col-md-4 col-sm-12">
                <button type="submit" class="btn btn-info fullwidth-nomargin" [attr.title]="((!model_family_search.dirty || model_family_search.invalid )  &&               
                (!specificationSearch.dirty || specificationSearch.invalid)
                 ) ? 'Please Select Model family or Specification' : 'Search Model family or Specification'" [ngStyle]="{'cursor': (((!model_family_search.dirty || model_family_search.invalid )  &&               
                  (!specificationSearch.dirty || specificationSearch.invalid))? 'not-allowed':'pointer'),
                  'background': (((!model_family_search.dirty || model_family_search.invalid )  &&               
                  (!specificationSearch.dirty || specificationSearch.invalid))? '#A9A9A9':'#007bbf')}" [disabled]=" 
                (!model_family_search.dirty || model_family_search.invalid )  &&               
                (!specificationSearch.dirty || specificationSearch.invalid)
                 ">Search</button>
              </div>
              <div class="form-group col-lg-4 col-md-4 col-sm-12">
                <button class="btn btn-info fullwidth-nomargin" title="Refresh Text" (click)="refreshSearchGrid()" type="button"> Clear</button>
              </div>
              <div class="form-group col-lg-4 col-md-4 col-sm-12">
                <button title="Export" class="btn btn-info fullwidth-nomargin" (click)="exportAsXLSX()">Export
                </button>
              </div>
              <div class="form-group col-lg-4 col-md-4 col-sm-12">
                <button class="btn btn-info fullwidth-nomargin" title="Delete all the records"  (click)="DeleteAll()" type="button"> Delete All</button>
              </div>

            </div>
          </form>
          <app-last-modified [lastUpdateds]="lastUpdateds"></app-last-modified>
        <div class="banner-margin-medium-top " *ngIf="showGrid">
          <ag-grid-angular  class="ag-theme-alpine vertical-scroll" pagination=true
            paginationPageSize=10 enableRangeSelection=true, [columnDefs]="columnDefs" [rowData]="rowData"
            [editType]="editType" [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)"
            (cellClicked)="onCellClicked($event)" [defaultColDef]="defaultColDef" [immutableColumns]="true"
            suppressClickEdit>
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>
