import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import {​​ MsaluserService }​​ from '../../msaluser.service';
import {ServicesErrorHandler} from '../Services/serviceerrorhandler';
import {Observable} from 'rxjs';

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient,
    
    private msalService: MsaluserService) { }

  //Generic function for all API calls---
  fnCallWebAPI(url, verb, requestData) {

    if (verb == 'get') {
      return this.http.get(url, {
        headers: new HttpHeaders().set('Content-Type', 'application/json')
      }).pipe(catchError(ServicesErrorHandler.handleError));
    }
    else {
      //post API
      return this.http.post(url, requestData, {

        headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Bearer '

          + this.msalService.GetAccessToken())

      }).pipe(catchError(ServicesErrorHandler.handleError));

    }
  }


}//close of export




