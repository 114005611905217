import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SerialNumber } from './serial-number';

@Injectable({
  providedIn: 'root'
})
export class SerialNumberService {

  private apiURL = "";
  
   

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<SerialNumber[]> {
    
    return this.httpClient.get<SerialNumber[]>("./assets/fluid/grid_data_serial.json")
    .pipe(
      catchError(this.errorHandler)
    )

  }  
  create(mfamily): Observable<SerialNumber> {   
    return this.httpClient.post<SerialNumber>(this.apiURL + '/posts/', JSON.stringify(mfamily), this.httpOptions)
    .pipe(
      catchError(this.errorHandler)
    )

  }  
  update(id, post): Observable<SerialNumber> {

    return this.httpClient.put<SerialNumber>(this.apiURL + '/posts/' + id, JSON.stringify(post), this.httpOptions)

    .pipe(

      catchError(this.errorHandler)

    )

  }
  delete(id){

    return this.httpClient.delete<SerialNumber>(this.apiURL + '/delete/' + id, this.httpOptions)

    .pipe(

      catchError(this.errorHandler)

    )

  }
  getById(id): Observable<SerialNumber> {
    
    return this.httpClient.get<SerialNumber>("./assets/fluid/serial_number_edit.json")
    .pipe(
      catchError(this.errorHandler)
    )

  }
  errorHandler(error) {

    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);

 }
}
