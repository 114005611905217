<app-banner [headerstring]="headerstring"></app-banner>
<div class="banner-small banner-md banner-xl-large ">
  <div class="container">
    <div class="row wide-gutters-xl">
      <div class="col-md-3">
        <app-sidebar [data]="market"></app-sidebar>

        <div class="d-none d-md-block">
        </div>
      </div>
      <div class="col-md-9">
        <div class="divide-10 divide-md-0"></div>
        <h3 class="h1 headline-large">Market</h3>
        <div class="m-top-2em">
          <div class="row ">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="input-group fullwidth">
                <input [(ngModel)]="searchModel" class="form-control" placeholder="Search..." />
                <div class="input-group-append">
                  <button class="btn btn-info" type="button" [attr.title]="(searchModel=='') ?'Please Enter text' : 'Search Market' " 
                  [disabled]="searchModel==''" (click)="fetchResults()"
                  [ngStyle]="{'cursor':(searchModel=='') ? 'not-allowed':'pointer','background':(searchModel=='') ? '#A9A9A9':'#007bbf'}"
                  >
                    <fa-icon [icon]="faSearch"><span class="sr-only">Search</span></fa-icon>
                  </button>
                  <button *ngIf="clearButtonShow" class="btn btn-info" type="button"  title="Clear" (click)="refreshSearchGrid()">
                    <fa-icon [icon]="faEraser"><span class="sr-only">Clear</span></fa-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <button class="btn btn-info fullwidth" data-toggle="modal" data-target="#market" title="Add Market" (click)="openModel()"><fa-icon [icon]="faPlusSquare"></fa-icon> Market</button>
            </div>
            <div class="banner-margin-medium-top col-lg-12 col-md-12 col-sm-12" *ngIf="showGrid">

              <ag-grid-angular  class="ag-theme-alpine vertical-scroll" pagination=true
                paginationPageSize=10 enableRangeSelection=true, [columnDefs]="columnDefs" [rowData]="rowData"
                [editType]="editType" [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)"
                (cellClicked)="onCellClicked($event)" [defaultColDef]="defaultColDef" [immutableColumns]="true"
                suppressClickEdit>
              </ag-grid-angular>
            </div>
          </div>
         
        </div>
          
       
      </div>
    </div>
  </div>
</div>

<!--Model-->
<div class="modal fade" data-backdrop="true" #marketPopup id="market" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Add Market </h2>
        <button type="button" class="close" #closeMarket (click)="closeModel()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label>Market Name</label>
            <input type="text" formControlName="market" class="form-control"  maxlength="50"
              [ngClass]="{ 'is-invalid': submitted && f.market.errors }" />
            <div *ngIf="submitted && f.market.errors" class="invalid-feedback">
              <div *ngIf="f.market.errors.required">Market Name is required</div>
              <div *ngIf="f.market.errors.pattern">Enter valid Market name</div>
            </div>
          </div>
          <div class="form-group">
            <label>Description</label>
            <textarea formControlName="description" class="form-control" maxlength="500"
              [ngClass]="{ 'is-invalid': submitted && f.description.errors }" rows="3"></textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
              <div *ngIf="f.description.errors.required">Description is required</div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="loading" title="Save Market" class="btn btn-primary">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Save
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>