import { Component, OnInit, ViewChild } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppError } from '../Common-Utils/globalerrorhandler/AppError';
import { environment } from 'src/environments/environment';
import { CommonService } from '../Common-Utils/Services/common.service';
import { MsaluserService } from '../msaluser.service';
import { ButtonRendererEditComponent } from '../Common-Utils/components/button-renderer-edit/button-renderer-edit.component';
import { NotificationService } from '../Common-Utils/Services/notification.service'
import { UIErrorHandler } from '../Common-Utils/globalerrorhandler/UIErrorHandler';
import * as $ from 'jquery'
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
@Component({
  selector: 'app-model-number',
  templateUrl: './model-number.component.html',
  styleUrls: ['./model-number.component.css']
})
export class ModelNumberComponent implements OnInit {
  faPlusSquare = faPlusSquare;
  faSearch = faSearch;
  faEraser=faEraser;
  searchModel: any;
  model: string
  showGrid: boolean = false
  
  @ViewChild('modelno') serialno;
  form: FormGroup;
  id: number;
  isAddMode: boolean = true;
  loading = false;
  submitted = false;
  frameworkComponents: any;

  headerstring: string = environment.bannerTexts.modelText
  public _genericErrorHandler = null;
  @ViewChild('modelno') modelno;
  @ViewChild('closeModelNumber') closeModelNumber;
  private gridApi;
  private gridColumnApi;
  private editType;
  private defaultColDef;
  clearButtonShow:boolean = false;

  constructor(

    private formBuilder: FormBuilder,
    private router: Router,

    private _commonService: CommonService,
    private msalService: MsaluserService,
    private notifyService: NotificationService
  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererEditComponent,
    }
    this.defaultColDef = {
      sortingOrder: ["asc", "desc"],
      component : environment.bannerTexts.modelText,
      stopEditingWhenGridLosesFocus: false,
      sortable: true,
      enableFilter: true,
      suppressKeyboardEvent: function (event) {
        if (!event.editing || event.event.code === "Enter")
          return true;
      }
    };
    this.editType = "fullRow";
  }
  columnDefs = [

    { headerName: 'Model Number', field: 'ModelName', minWidth: 100, maxWidth: 150, resizable: true, editable: true },
    { headerName: 'Model Tag', field: 'ModelTag', minWidth: 100,maxWidth: 150,  resizable: true, editable: true },
    { headerName: 'Description1', field: 'Description1', resizable: true, minWidth: 100, flex: 2, editable: true,tooltipField: 'Description1' },
    { headerName: 'Description2', field: 'Description2', resizable: true, minWidth: 100, flex: 2, editable: true },
    {
      headerName: 'Status', field: 'Status', minWidth: 25, maxWidth: 75, editable: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values:environment.constants.statusDropdownValues//For Status dropdown
      }
    },
    {
      headerName: 'Action',
      field: 'action', minWidth: 100, maxWidth: 125,
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        cancelOtherRowEditors: this.cancelOtherRowEditors.bind(this),
        updateRowEditors: this.fnBindUpdateGrid.bind(this),//For edit--to get selected row values from button-renderededit component
        deleteRowEditors: this.fnDeleteModel.bind(this)//For delete--to get selected row values from button-renderededit component
      },
    }
  ];
  rowData: any;


  ngOnInit(): void {
    this.model = "model"
    this.form = this.formBuilder.group({
      modelNumber: ['', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      modelTag: ['', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      description1: [''],
      description2: [''],
      active: ['']
    });

    this._genericErrorHandler = new UIErrorHandler();
    //Calling binding grid result oninit
    this.searchModel='';
    this.fetchResults();
  }

  get f() { return this.form.controls; }
  fnNewForm() {
    this.isAddMode = true;
    this.form.reset();
  }

  /* On Submit of add model number form */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.createModel()
    }
  }

  cancelOtherRowEditors(currentRowIndex) {
    //Renderer implies all rows of grid
    const renderers = this.gridApi.getCellRendererInstances();

    renderers.forEach(function (renderer) {
      if (!renderer._agAwareComponent.isNew &&
        currentRowIndex !== renderer._params.node.rowIndex) {
        renderer._agAwareComponent.onCancelClick();
      }
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }

  onCellClicked(params) {
    if (params.node.field !== 'action') {
      this.cancelOtherRowEditors(params.node.rowIndex);
    }
  }

  /* Model Functionalites*/
  openModel() {
    this.submitted =false
    this.form.reset()
   
  }
  closeModel() {
    
    this.closeModelNumber.nativeElement.click();
    $('.modal-backdrop').remove();
  }

  /* createModel - To create new model */
  private createModel() {
    this.loading = false;    
    const addModelData: any = {
      'ModelName': this.form.controls["modelNumber"].value,
      'ModelTag': this.form.controls["modelTag"].value,
      'Description1': this.form.controls["description1"].value,
      'Description2': this.form.controls["description2"].value,
      'Status': true,
      'CreatedBy': this.msalService.getCurrentUserInfo()

    };
    
    
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Maintenance/InsertModel', 'post', addModelData).subscribe(res => {
    
      if(res){
        if(res==environment.constants.Success){
          this.notifyService.showSuccess(environment.messageParams.msgModelAdd, "Success")
          this.router.navigateByUrl('/admin/model');
          this.closeModel();
          this.searchModel = '';
        }else{
          this.notifyService.showError(environment.messageParams.msgModelDuplicateError,"Error")
          this.searchModel = '';
        }
      }
      
      
      
      
      else{
        this.notifyService.showError(environment.messageParams.msgSaveError, "Error")
      }
     
    },
    (error: AppError) => {   
     
      this._genericErrorHandler.handleError(error, this.notifyService, this.router);
     
    })
  }
  /*fnBindUpdateGrid -Calling Update API  */
  fnBindUpdateGrid(e) {  
    if(e.selectedData['ModelName']=='' || e.selectedData['ModelName']==undefined){
      this.notifyService.showError(environment.messageParams.msgModelEmpty, "Error")
      this.fetchResults()
      return;
    }
    if(e.selectedData['ModelTag']=='' || e.selectedData['ModelTag']==undefined){
      this.notifyService.showError(environment.messageParams.msgModelTagEmpty, "Error")
      this.fetchResults()
      return;
    }
    const updateModelData: any = {
      'ModelID': e.selectedData['ModelID'],
      'ModelName': e.selectedData["ModelName"],
      'ModelTag': e.selectedData["ModelTag"],
      'Description1': e.selectedData["Description1"],
      'Description2': e.selectedData["Description2"],
      'Status': e.selectedData['Status'] == 'Active' ? true : false,
      'ModifiedBy': this.msalService.getCurrentUserInfo()

    };
    //Update API call
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Maintenance/UpdateModel', 'post', updateModelData).subscribe(res => {

      if (res) {     
        if(res==environment.constants.Success){
          this.notifyService.showSuccess(environment.messageParams.msgModelUpdate, "Success")
        }else{
          this.notifyService.showError(environment.messageParams.msgModelDuplicateError, "Error")
        }
         //Reloading grid after duplicate or success
         this.fetchResults()
      }
      else {
        this.notifyService.showError(environment.messageParams.msgUpdateError, "Error")
      }
    

    }, (error: AppError) => {
      this._genericErrorHandler.handleError(error, this.notifyService, this.router);
    })
  }
  //Calling Delete API
  fnDeleteModel(e) {

    //Getting ID to pass to Delete API 
    let _mfid = e[0]['ModelID'];

    this._commonService.fnCallWebAPI(environment.baseUrl + 'Maintenance/DeleteModel?id=' + _mfid, 'post', null).subscribe(res => {
     
      if (res) {
        if (res == environment.constants.Success) {
          //Reloading grid after delete successful
          this.fetchResults();
          this.notifyService.showSuccess(environment.messageParams.msgModelDeleted, "Success")
        } else if (res == environment.constants.MappingExists) {
          this.notifyService.showWarning(environment.messageParams.msgModelDeleteMappingExists, "Warning")
        }
      }
      else {
        this.notifyService.showError(environment.messageParams.msgDeleteError, "Error")
      }
    }, (error: AppError) => {
      this._genericErrorHandler.handleError(error, this.notifyService, this.router);
    })
  }

 
  /* To load grid data based on search */
  fetchResults() {
   
      this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetModel?getAll=true&searchtext=' + this.searchModel, 'get', null).subscribe(
        res => {
      
          if (res != null) {
            this.rowData = res["TBL_Result"];
            this.showGrid = true;
            if(this.searchModel!=''){
              this.clearButtonShow=true; 
            }  
            
          }
        },
        (error: AppError) => {
          this._genericErrorHandler.handleError(error, this.notifyService, this.router);
          this.fetchResults();
        }
      );
   
  }
  refreshSearchGrid(){
    console.log("search clear")
    this.searchModel='';
    this.fetchResults();
    this.clearButtonShow=false;
    
    
  }





}//close of export
