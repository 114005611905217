<div class="search-component m-bottom-1em">
  <div class="container">
    <div class="row wide-gutters-lg">
      <div class="col-md-6 col-lg-6 col-xl-6">
        <form name="form" class="form-width-xs" #f="ngForm" novalidate>
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-12 col-md-5 col-lg-5 col-form-label">Market</label>
            <div class="col-sm-12 col-md-7 col-lg-7">
              OH-Transmissions
            </div>
          </div>
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-12 col-md-5 col-lg-5 col-form-label">Search By:</label>
            <!-- Search By -->
            <div class="col-sm-12 col-md-7 col-lg-7">
              <div *ngIf="loggedIn">
                <select name="inlineRadioOptions" [ngSwitch]="true" [(ngModel)]="type" class="form-control"
                  (change)="onSearchChange($event.target.value)">
                  <option value="0" selected>Serial Number</option>
                  <option value="1" selected>Model</option>
                  <option value="2" selected>Model Family</option>
                </select>
              </div>
              <div *ngIf="!loggedIn">
                <select name="inlineRadioOptions" [ngSwitch]="true" [(ngModel)]="type" class="form-control"
                  (change)="onSearchChange($event.target.value)">
                  <option value="0" selected>Serial Number</option>
                  <option value="1" selected>Model</option>
                </select>
              </div>

            </div>
          </div>
        </form>
        <div *ngIf="type == 2">
          <form name="form" class="form-width-xs" (ngSubmit)="f.form.valid && onSearchFluid(buttontype)" #f="ngForm"
            novalidate>
            <!-- Search By ModelFamily (type=0)-->
            <div class="form-group row" *ngIf="showMarket">
              <label for="inputEmail3" class="col-sm-12 col-md-4 col-lg-4 col-form-label">Market</label>
              <div class="col-sm-12 col-md-8 col-lg-8">
                <select *ngIf="showMarket" name="selectedMarket" [(ngModel)]="model.selectedMarket"
                  #selectedMarket="ngModel" (change)="onMarketChange($event.target.value)" [ngClass]="{
                    'is-invalid':
                      f.submitted && selectedMarket.invalid && showMarket
                  }" class="form-control">
                  <option value="" selected>Select Market</option>
                  <option *ngFor="let market of market_data" value="{{ market.Id }}">
                    {{ market.Name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-12 col-md-5 col-lg-5 col-form-label">Model Family</label>
              <div class="col-sm-12 col-md-7 col-lg-7">
                <select *ngIf="model_family" name="selectedModel_family" [(ngModel)]="model.selectedModel_family"
                  #selectedModel_family="ngModel" [ngClass]="{
                    'is-invalid': f.submitted && selectedModel_family.invalid
                  }" required class="form-control" (blur)="onKeyUpModelFamily($event.target.value)">
                  <option value="" selected>Select Model Family</option>
                  <option *ngFor="let modelfamily of model_family" value="{{ modelfamily.ModelFamilyID }}">
                    {{ modelfamily.ModelFamilyName }}
                  </option>
                </select>
                <div *ngIf="f.submitted && selectedModel_family.invalid" class="invalid-feedback">
                  <div *ngIf="selectedModel_family.errors.required">
                    ModelNumber is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <label for="inputEmail3" class="col-sm-12 col-md-5 col-lg-5 col-form-label">Minimum Prevailing Ambient
                Temperature</label>
              <div class="col-sm-12 col-md-7 col-lg-7">
                <div class="form-group">
                  <mat-slider class="example-margin mat-slider-mt-2" name="temperature" #temperature="ngModel"
                    [max]="max" [min]="min" [step]="step" [displayWith]="formatLabel" [thumbLabel]="thumbLabel"
                    [(ngModel)]="model.temperature" [vertical]="vertical">
                  </mat-slider>
                </div>
              </div>
            </div>
            <div>
              <button [disabled]="!enableButton" [attr.title]="
                    !enableButton ? 'Please Select Model Family' : 'Search Model Family'
                  " [ngStyle]="{
                    cursor: !enableButton ? 'not-allowed' : 'pointer',
                    background: !enableButton ? '#A9A9A9' : '#007bbf'
                  }" value="4c7c4a44-7446-415e-ad1d-3612d3a5f0be" data-f-is-finalized="false"
                data-f-is-progressive-submit="true" data-f-type="submitbutton" data-f-element-name="__field_2086" class="
                    Form__Element
                    FormExcludeDataRebind
                    FormSubmitButton
                    submit-btn" (click)="onSearchFluid('search')">
                <div class="btn-text"><fa-icon [icon]="faSearch"></fa-icon>&nbsp;Search</div>
              </button>
              <button title="Print" class="
                    submit-btn" (click)="onSearchFluid('print')">
                <div class="btn-text"><fa-icon [icon]="faPrint"></fa-icon>&nbsp;Print
                </div>
              </button>
            </div>
          </form>
        </div>
        <!-- Search By Serial Number(type=0) -->
        <div *ngIf="type == 0">
          <form name="form" class="form-width-xs" (ngSubmit)="f.form.valid && onSearchFluid(buttontype)" #f="ngForm"
            novalidate>
            <div class="row">
              <label for="inputEmail3" class="col-sm-12 col-md-5 col-lg-5 col-form-label">Serial
                n<span>&#176;</span></label>
              <div class="col-sm-12 col-md-7 col-lg-7">
                <input type="text" class="form-control" name="serialNo" [(ngModel)]="model.serialNo" #serialNo="ngModel"
                  (keyup)="onKeyUpSerialNo($event)" [ngClass]="{ 'is-invalid': f.submitted && serialNo.invalid }"
                  required (keydown.enter)="fnBindSerialNoInfo($event.target.value)"
                  (blur)="fnBindSerialNoInfo($event.target.value)" />
                <!-- (blur)="fnBindSerialNoInfo($event.target.value)" -->
                <div *ngIf="f.submitted && serialNo.invalid" class="invalid-feedback">
                  <div *ngIf="serialNo.errors.required">
                    Serial Number is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-top-1em">
              <label for="inputEmail3" class="col-sm-12 col-md-5 col-lg-5 col-form-label">Minimum Prevailing Ambient
                Temperature</label>
              <div class="col-sm-12 col-md-7 col-lg-7">
                <div class="form-group">
                  <mat-slider class="example-margin mat-slider-mt-2" name="temperature" #temperature="ngModel"
                    [max]="max" [min]="min" [step]="step" [displayWith]="formatLabel" [thumbLabel]="thumbLabel"
                    [(ngModel)]="model.temperature" [vertical]="vertical">
                  </mat-slider>
                </div>
              </div>
            </div>
            <div>
              <button [disabled]="!enableButton" [attr.title]="
                    !enableButton ? 'Please Select Serial Number' : 'Search Serial Number'
                  " [ngStyle]="{
                    cursor: !enableButton ? 'not-allowed' : 'pointer',
                    background: !enableButton ? '#A9A9A9' : '#007bbf'
                  }" value="4c7c4a44-7446-415e-ad1d-3612d3a5f0be" data-f-is-finalized="false"
                data-f-is-progressive-submit="true" data-f-type="submitbutton" data-f-element-name="__field_2086" class="
                    Form__Element
                    FormExcludeDataRebind
                    FormSubmitButton
                    submit-btn" (click)="onSearchFluid('search')">
                <div class="btn-text"><fa-icon [icon]="faSearch"></fa-icon>&nbsp;Search</div>
              </button>
              <button title="Print" class="
                    submit-btn" (click)="onSearchFluid('print')">
                <div class="btn-text"><fa-icon [icon]="faPrint"></fa-icon>&nbsp;Print</div>
              </button>
            </div>
          </form>
        </div>
        <!-- Search By Model Number(type=1) -->
        <div *ngIf="type == 1">
          <form name="form" class="form-width-xs" (ngSubmit)="f.form.valid && onSearchFluid(buttontype)" #f="ngForm"
            novalidate>
            <div class="row">
              <label for="inputEmail3" class="col-sm-12 col-md-5 col-lg-5 col-form-label">Model Number</label>
              <!-- ModelNumber(autocomplete) -->
              <div class="col-sm-12 col-md-7 col-lg-7">
                <mat-form-field class="full-width" *ngIf="model_number" appearance="outline">
                  <input matInput type="text" appCommonDirective class="full-width mat_form_field_padding"
                    [formControl]="myControl" [matAutocomplete]="auto" name="selectedModelNumber" [ngClass]="{
                      'is-invalid': f.submitted && selectedModelNumber.invalid
                    }" required (keyup)="onKeyUp($event)" />

                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" panelWidth="270px"
                    (optionSelected)="onSelectModelNumber($event)" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{ option.ModelName }} ({{ option.ModelTag }})
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <label for="inputEmail3" class="col-sm-12 col-md-5 col-lg-5 col-form-label">Minimum Prevailing Ambient
                Temperature</label>
              <div class="col-sm-12 col-md-7 col-lg-7">
                <div class="form-group">
                  <mat-slider class="example-margin mat-slider-mt-2" name="temperature" #temperature="ngModel"
                    [max]="max" [min]="min" [step]="step" [displayWith]="formatLabel" [thumbLabel]="thumbLabel"
                    [(ngModel)]="model.temperature" [vertical]="vertical">
                  </mat-slider>
                </div>
              </div>
            </div>
            <div>
              <button [disabled]="!enableButton" [attr.title]="
                    !enableButton ? 'Please Select Model' : 'Search Model'
                  " [ngStyle]="{
                    cursor: !enableButton ? 'not-allowed' : 'pointer',
                    background: !enableButton ? '#A9A9A9' : '#007bbf'
                  }" value="4c7c4a44-7446-415e-ad1d-3612d3a5f0be" data-f-is-finalized="false"
                data-f-is-progressive-submit="true" data-f-type="submitbutton" data-f-element-name="__field_2086" class="
                    Form__Element
                    FormExcludeDataRebind
                    FormSubmitButton
                    submit-btn" (click)="onSearchFluid('search')">
                <div class="btn-text"><fa-icon [icon]="faSearch"></fa-icon>&nbsp;Search</div>
              </button>
              <button title="Print" class="
                  submit-btn" (click)="onSearchFluid('print')">
                <div class="btn-text"><fa-icon [icon]="faPrint"></fa-icon>&nbsp;Print
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="block-epi landingproductssliderblock">
          <div class="divide-50 divide-md-0"></div>

          <div id="modelInfo" class="block-gutter block-md-medium bg-lighter-gray pad-1em"
            *ngIf="type == 1 && show_model_number_information">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"
              onclick="$('#modelInfo').hide()">
              <!-- <span aria-hidden="true">×</span> -->
            </button>
            <h3 class="h6 text-blue">Product Information</h3>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <p class="productLabel"><strong>Model Tag</strong></p>
              <p>
                <label> {{ model_number_information.ModelTag }} </label>
              </p>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <p class="productLabel"><strong>Model Number</strong></p>
              <label> {{ model_number_information.ModelName }} </label>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <p class="productLabel"><strong>Description 1</strong></p>
              <label> {{ model_number_information.Description1 }} </label>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <p class="productLabel"><strong>Description 2</strong></p>
              <label> {{ model_number_information.Description2 }} </label>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <p class="productLabel"><strong>Model Family</strong></p>
              <label> {{ model_number_information.ModelFamilyName }} </label>
            </div>
          </div>
          <div id="serialInfo" class="block-gutter block-md-medium bg-lighter-gray pad-1em"
            *ngIf="type == 0 && show_serial_number_information">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"
              onclick="$('#serialInfo').hide()">
              <!-- <span aria-hidden="true">×</span> -->
            </button>
            <h3 class="h6 text-blue">Product Information</h3>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <p class="productLabel"><strong>Model Number</strong></p>
              <label> {{ serial_number_information.ModelName }} </label>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <p class="productLabel"><strong>Model Tag</strong></p>
              <label> {{ serial_number_information.ModelTag }} </label>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <p class="productLabel"><strong>Serial Number</strong></p>
              <label>{{ serial_number_information.SerialNumber }} </label>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <p class="productLabel"><strong>Manufacture Date</strong></p>
              <label> {{ serial_number_information.ManufactureDate }} </label>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <p class="productLabel"><strong>Description</strong></p>
              <label> {{ serial_number_information.Description1 }} </label>
            </div>
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <p class="productLabel"><strong>Model Family</strong></p>
              <label> {{ serial_number_information.ModelFamilyName }} </label>
            </div>
          </div>

          <div class="block-gutter block-md-medium bg-lighter-gray pad-1em" *ngIf="show_no_product_found">
            <h2 class="h6 text-blue text-center">Sorry ! No Product Found</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-ui-loader></ngx-ui-loader>