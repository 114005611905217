import { Directive,HostListener } from '@angular/core';

@Directive({
  selector: '[appCommonDirective]'
})
export class CommonDirectiveDirective {

  constructor() { }
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

}
