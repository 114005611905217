<app-banner [headerstring]="headerstring"></app-banner>
<div class="banner-small banner-md banner-xl-large">
  <div class="container">
    <div class="row wide-gutters-xl">
      <div class="col-md-3">
        <app-sidebar [data]="serialmodelMaping"></app-sidebar>
        <div class="d-none d-md-block"></div>
      </div>
      <div class="col-md-9">
        <div class="divide-10 divide-md-0"></div>
        <h3 class="h1 headline-large">Add Model - Serial Number Mapping</h3>
        <div class="">
          <form [formGroup]="form" (ngSubmit)=onSubmit()>
            <div class="form-row">
              <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label>Model Number</label>
                <mat-form-field class="full-width marigin_top_minus_5px matform-field-full-width" appearance="outline">

                  <input matInput type="text" class="full-width" formControlName="selectedModel" [matAutocomplete]="auto"
                    name="selectedModel" required (keyup)="onKeyUp($event)">
                                        
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" panelWidth="270px"
                    (optionSelected)='onSelectModelNumber($event)' [displayWith]="displayFn">
                     <mat-option *ngFor="let option of filteredOptions |async" [value]="option">
                      {{option.ModelName}}
                    </mat-option>
                  </mat-autocomplete>   
                  <mat-error *ngIf="submitted && m.selectedModel.errors">
                    <span *ngIf="m.selectedModel.errors">Model is required.</span>                  
                  </mat-error>                          
                </mat-form-field>

              </div>
              <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label>Serial Number</label>
                <input id="SerialNumber" type="text" formControlName="SerialNumber" maxlength="50" class="form-control" required [ngClass]="{ 'is-invalid': submitted && m.SerialNumber.errors }"
                 />
                  <div *ngIf="submitted && m.SerialNumber.errors" class="invalid-feedback">
                    <div *ngIf="m.SerialNumber.errors.required">Serial Number  is required</div>
                    <div *ngIf="m.SerialNumber.errors.pattern">Enter valid Serial Number</div>
                </div>
              </div>
              <div class="form-group col-lg-3 col-md-6 col-sm-12">
                <label> Production Date</label>
                <input id="ProductionDate"  type="text"
                  formControlName="ProductionDate" class="form-control"  [ngClass]="{ 'is-invalid': submitted && m.ProductionDate.errors }"/>
                  <div *ngIf="submitted && m.ProductionDate.errors" class="invalid-feedback">                
                    <div *ngIf="m.ProductionDate.errors.pattern">Enter valid date </div>
                </div>
              </div>    
              <div class="m-top-2em col-lg-3 col-md-6 col-sm-12">
                <button type="submit" class="btn btn-info fullwidth-nomargin" title="Add Model -Serial Number">
                  <fa-icon [icon]="faPlusSquare"></fa-icon>  Add</button>
              </div>  
            </div>
          </form>
        </div>
        
        <h3 class="h1 headline-large m-search-top-1em ">Search </h3>
            <form name="form" [formGroup]="searchForm" (ngSubmit)="searchFunc()" novalidate>
             
              <div class="form-row">
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label >Model Number</label>
                  <mat-form-field class="full-width marigin_top_minus_5px mat_field_width matform-field-full-width " appearance="outline">
                    <input type="text" class="full-width mat_form_field_padding" matInput formControlName="search_model_number" [matAutocomplete]="auto1"                         
                      required (keyup)="onKeyUp1($event)">
                    <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" panelWidth="270px">
                      <mat-option *ngFor="let option of searchFilteredOptions |async" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>

                  <span *ngIf="!search_form_valid && !export" class="has-error">
                    Please enter Model or Serial Number 
                  </span>
                 
                </div>
                <div class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label >Serial Number</label>
                  <input id="search_serial_number"formControlName="search_serial_number" type="text" name="serial_number_search" class="form-control" required 
                  />                      
                </div>
            
                <div class="form-group col-lg-4 col-md-4 col-sm-12">
                  <button type="submit" class="btn btn-info fullwidth-nomargin "
                  [attr.title]="(  (!searchForm.get('search_model_number').dirty || searchForm.get('search_model_number').invalid )  &&               
                  (!searchForm.get('search_serial_number').dirty || searchForm.get('search_serial_number').invalid)) ? 'Please Select Model or Serial Number' : 'Search Model or Serial Number'"
                  [ngStyle]="{'cursor': (((!searchForm.get('search_model_number').dirty || searchForm.get('search_model_number').invalid )  &&               
                  (!searchForm.get('search_serial_number').dirty || searchForm.get('search_serial_number').invalid))? 'not-allowed':'pointer'),
                  'background': (((!searchForm.get('search_model_number').dirty || searchForm.get('search_model_number').invalid )  &&               
                  (!searchForm.get('search_serial_number').dirty || searchForm.get('search_serial_number').invalid))? '#A9A9A9':'#007bbf')}"
                   [disabled]=" 
                  (!searchForm.get('search_model_number').dirty || searchForm.get('search_model_number').invalid )  &&               
                  (!searchForm.get('search_serial_number').dirty || searchForm.get('search_serial_number').invalid)
                   "
                  >Search</button>
                </div>
                <div class="form-group col-lg-4 col-md-4 col-sm-12">
                  <button class="btn btn-info fullwidth-nomargin" title="Refresh Text" (click)="refreshSearchGrid()" type="button"> Clear</button>
              </div>
              <div class="form-group col-lg-4 col-md-4 col-sm-12">
                <button title="Export" class="btn btn-info fullwidth-nomargin" (click)="exportAsXLSX()">Export
                </button>
              </div>
               </div>
             
            </form>
          <div>
          <app-last-modified [lastUpdateds]="lastUpdateds"></app-last-modified>
        </div>       
        <div class="banner-margin-medium-top " *ngIf="showGrid">
          <ag-grid-angular style="width: 100%; height: 530px;" class="ag-theme-alpine" pagination=true
            paginationPageSize=10 enableRangeSelection=true, [rowData]="rowData" [columnDefs]="columnDefs"
            [frameworkComponents]="frameworkComponents">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-ui-loader></ngx-ui-loader>