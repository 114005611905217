import { BadRequestError } from '../globalerrorhandler/BadRequestError';
import { UnAuthorizedError } from '../globalerrorhandler/UnauthorizedError';
import { ForbiddenError } from '../globalerrorhandler/ForbiddenError';
import { NotFoundError } from '../globalerrorhandler/NotFoundError';
import { InternalServerError } from '../globalerrorhandler/InternalServerError';
import { AppError } from '../globalerrorhandler/AppError';
import { throwError } from 'rxjs';


import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export class ServicesErrorHandler {

    /* This is a generic handler to handle all service related error responses */
    public static handleError(error: HttpErrorResponse) {
        const exception = error.error;
        console.log('err at handler',error);
        console.log('error.error',exception);
        if(exception instanceof ErrorEvent){
            var msg=error.statusText;
            switch (error.status) {
                case 400: return throwError(new BadRequestError( exception.message ));
                case 401: return throwError(new UnAuthorizedError( exception.message ));
                case 403: return throwError(new ForbiddenError( exception.message ));
                case 404: return throwError(new NotFoundError( exception.message ));
                case 500: return throwError(new InternalServerError( exception.message));
                default: return  throwError(new AppError(exception.message));
            }
        }
        else{
            switch (error.status) {
                case 400: return throwError(new BadRequestError( error.message ));
                case 401: return throwError(new UnAuthorizedError( error.message ));
                case 403: return throwError(new ForbiddenError( error.message ));
                case 404: return throwError(new NotFoundError( error.message ));
                case 500: return throwError(new InternalServerError( error.message));
                default: return  throwError(new AppError(error.message));
            }

        }
       
    }
}
