import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css'],
})
export class SearchResultsComponent implements OnInit {
  verifyUrl : string = environment.fmpUrl + environment.approvalVerifyUrl;
  @Input() resultedFluids: any = [];

  constructor() {}

  ngOnInit(): void {}
}
