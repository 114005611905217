import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment'; 
@Component({
  selector: 'app-accessdenied',
  templateUrl: './accessdenied.component.html',
  styleUrls: ['./accessdenied.component.css']
})
export class AccessdeniedComponent implements OnInit {
  headerstring: string = environment.bannerTexts.accessDenied;
  accessDeniedMessage: string = environment.messageParams.msgAccessDenied;
  backtoHomeMessage: string = environment.messageParams.msgNavigateToHome;
  constructor() { }

  ngOnInit(): void {
  }

}
