<app-banner [headerstring]="headerstring"></app-banner>
<div class="banner-small banner-md banner-xl-large">
    <div class="container">
        <div class="row wide-gutters-xl">
            <div class="col-md-3">
              <app-sidebar [data]="marketfamilyMaping"></app-sidebar>
              <div class="d-none d-md-block"></div>
            </div>
            <div class="col-md-9">
                <div class="divide-10 divide-md-0"></div>
                <h3 class="h1 headline-large"> Add Market - Model Family Mapping</h3>  
                <div class="">
                  <form  [formGroup]="form" (ngSubmit)=onSubmit()>
                    <div class="form-row">
                      <div class="form-group col-lg-4 col-md-4 col-sm-12">
                          <label >Market </label>
                          <select *ngIf="market_data" formControlName="selectedMarket" required  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.selectedMarket.errors }" >
                              <option value=""selected>Select Market</option>
                              <option *ngFor="let market of market_data" value= {{market.Id}}>{{market.Name}}</option>
                              </select>       
                              <div *ngIf="submitted && f.selectedMarket.errors" class="invalid-feedback">
                                <div *ngIf="f.selectedMarket.errors.required">Market  is required</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-4 col-md-4 col-sm-12">
                        <label >Model Family</label>
                        <select *ngIf="model_family_data" formControlName="selectedModelFamily" required  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.selectedModelFamily.errors }"  >
                          <option value=""selected>Select Model Family</option>
                          <option *ngFor="let modelfamily of model_family_data" value= {{modelfamily.ModelFamilyID}}>{{modelfamily.ModelFamilyName}}</option>
                          </select>   
                          <div *ngIf="submitted && f.selectedModelFamily.errors" class="invalid-feedback">
                            <div *ngIf="f.selectedModelFamily.errors.required">Model Family  is required</div>
                        </div> 
                        </div>
                      
                      <div class="m-top-2em col-lg-4 col-md-4 col-sm-12">
                        <button type="submit" class="btn btn-info fullwidth-nomargin" title="Add Market-Model Family ">
                          <fa-icon [icon]="faPlusSquare"></fa-icon>  Add</button>
                      </div>
                     </div>
                  </form> 
                </div> 
                <h3 class="h1 headline-large m-search-top-1em ">Search</h3>
               
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="input-group">
                        <input [(ngModel)]="searchFieldText" class="form-control" placeholder="Search by Model family" />
                        <div class="input-group-append">            
                          <button class="btn btn-info" type="button" [attr.title]="(searchFieldText=='') ?'Please Enter text' : 'Search Model Family' " 
                          [disabled]="searchFieldText==''" (click)="fetchResults()"
                          [ngStyle]="{'cursor':(searchFieldText=='') ? 'not-allowed':'pointer','background':(searchFieldText=='') ? '#A9A9A9':'#007bbf'}"
                          >
                            <fa-icon [icon]="faSearch"><span class="sr-only">Search</span></fa-icon>
                          </button>
                          <button *ngIf="clearButtonShow" class="btn btn-info" type="button"  title="Clear" (click)="refreshSearchGrid()">
                            <fa-icon [icon]="faEraser"><span class="sr-only">Clear</span></fa-icon>
                          </button>
                        </div>
                        <div *ngIf="submittedSearch && s.searchinput.errors" class="invalid-feedback">
                          <div *ngIf="s.searchinput.errors.required">Model Family  is required</div>
                      </div> 
                      </div>
                    </div>      
                 
                 
                </div>
                <div class="banner-margin-medium-top" *ngIf="showGrid">
  
                  <ag-grid-angular 
                  
                  class="ag-theme-alpine vertical-scroll"
                  pagination=true
                  paginationPageSize=10
                  enableRangeSelection=true,
                  [rowData]="rowData" 
                  [columnDefs]="columnDefs"
                  [frameworkComponents]="frameworkComponents"
                  >
                  
                  </ag-grid-angular>
                </div>
                 
        
            </div>        
      </div>
    </div>
  </div>
