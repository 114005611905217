import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { ButtonRendererDeleteComponent } from '../Common-Utils/components/button-renderer-delete/button-renderer-delete.component'
import { environment } from 'src/environments/environment';
import { CommonService } from '../Common-Utils/Services/common.service';
import { MsaluserService } from '../msaluser.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../Common-Utils/Services/notification.service'
import { UIErrorHandler } from '../Common-Utils/globalerrorhandler/UIErrorHandler';
import { AppError } from '../Common-Utils/globalerrorhandler/AppError';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
@Component({
  selector: 'app-market-model-family-mapping',
  templateUrl: './market-model-family-mapping.component.html',
  styleUrls: ['./market-model-family-mapping.component.css']
})
export class MarketModelFamilyMappingComponent implements OnInit {
  faPlusSquare = faPlusSquare;
  showGrid: boolean = false;
  form: FormGroup;
  searchForm: FormGroup;
  faSearch = faSearch;
  faEraser=faEraser;
  modelFamilyDataSerach: any;
  marketfamilyMaping: string;
  market_data: any = [];
  model_family_data: any = [];
  searchFieldText: any;
  frameworkComponents: any;
  headerstring: string = environment.bannerTexts.MarketModelFamilyMappingText
  rowData: any;
  submitted = false;
  submittedSearch = false
  public _genericErrorHandler = null;
  clearButtonShow:boolean = false;

  /* This variable refer to template driven form of search*/
  modelSearch: any = {

  };
  constructor(
    private formBuilder: FormBuilder,
    private _commonService: CommonService,
    private router: Router,
    private notifyService: NotificationService,
    private msalService: MsaluserService
  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererDeleteComponent,
    }
  }
  model_number = [];
  model_family = [];
  columnDefs = [

    { headerName: 'Market', field: 'Name', resizable: true ,minWidth:50,flex:2},
    { headerName: 'Model Family', field: 'ModelFamily', resizable: true, minWidth: 100, maxwidth:300,flex: 4 },
    {
      headerName: 'Action',
      field: 'action', minWidth: 100, maxWidth: 125,
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.fnBindDeleteMapping.bind(this),
        label: 'Click 1'
      }
    },
  ];


  ngOnInit(): void {
    this.marketfamilyMaping = "marketfamilyMaping"
    this._genericErrorHandler = new UIErrorHandler();
    this.fnBindMappinfForm();
    this.fngetModelFamilyDropDown()
    this.fnGetMarketDropDown()
    //Calling binding grid result oninit
    this.searchFieldText = '';
    this.fetchResults();
  }
  fnBindMappinfForm() {
    this.form = this.formBuilder.group({
      selectedMarket: ['', Validators.required],
      selectedModelFamily: ['', Validators.required],
      active: ['']
    });
  }

  get f() { return this.form.controls; }
  get s() { return this.searchForm.controls; }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const addMapData: any = {
      'MarketId': Number(this.form.controls["selectedMarket"].value),
      'ModelFamilyId': Number(this.form.controls["selectedModelFamily"].value),
      'CreatedBy': this.msalService.getCurrentUserInfo()

    };

    this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/MapMarketModelFamily', 'post', addMapData).subscribe(res => {
      if (res) {
        if (res == environment.constants.Success) {
          this.router.navigateByUrl('admin/market-family-mapping');
          this.notifyService.showSuccess(environment.messageParams.msgMarketModelFamilyMappingSuccess, "Success")
          this.form.reset('');
          this.submitted = false;
          this.fngetModelFamilyDropDown()
          this.fnGetMarketDropDown()
        } else {
          this.notifyService.showError(environment.messageParams.msgSaveError, "Error")
        }
      } else {
        this.notifyService.showError(environment.messageParams.msgSaveError, "Error")
      }

    },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      })
  }
  fnBindDeleteMapping(e) {
    //Getting ID to pass to Delete API 
    let _mfid = e.rowData.Id;
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/DeleteMarketModelFamily?id=' + _mfid, 'post', null).subscribe(res => {

      if (res) {
        if (res == environment.constants.Success) {
          this.notifyService.showSuccess(environment.messageParams.msgMarketModelFamilyMappingDeleted, "Success")
          //Reload the grid
          this.fetchResults()
        } else { this.notifyService.showError(environment.messageParams.msgDeleteError, "Error") }
      }
      else {
        this.notifyService.showError(environment.messageParams.msgDeleteError, "Error")
      }
    },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      })
  }

  fetchResults() {
    this._commonService.fnCallWebAPI(
      environment.baseUrl + 'Search/GetMarketModelFamily?_modelFamily=' + this.searchFieldText
      , 'get', null).subscribe(
        res => {
          if (res != null) {
            this.rowData = res["TBL_Result"];
            this.showGrid = true;
            this.submittedSearch = false;
            if(this.searchFieldText!=''){
              this.clearButtonShow=true; 
            }  
          }
        },
        (error: AppError) => {
          this._genericErrorHandler.handleError(error, this.notifyService, this.router);
        }
      );
  }

  /* fngetModelFamilyDropDown :  To get the Model family list from service  */
  fngetModelFamilyDropDown() {
    this._commonService.fnCallWebAPI(
      environment.baseUrl + 'Search/GetModelFamily?isUnmapped=true', 'get', null).subscribe(
        res => {

          if (res != null) {
            this.model_family_data = res["TBL_Result"];

          }
        },
        (error: AppError) => {
          this._genericErrorHandler.handleError(error, this.notifyService, this.router);
        }
      );

  }
  /* fnGetMarketDropDown :  To get the Market list from service  */
  fnGetMarketDropDown() {
    this._commonService.fnCallWebAPI(
      environment.baseUrl + 'Search/GetMarket', 'get', null).subscribe(
        res => {

          if (res != null) {
            this.market_data = res["TBL_Result"];

          }
        },
        (error: AppError) => {
          this._genericErrorHandler.handleError(error, this.notifyService, this.router);
        }
      );
  }
  refreshSearchGrid(){
    console.log("search clear")
    this.searchFieldText='';
    this.fetchResults();
    this.clearButtonShow=false;
    
    
  }
}
