import { Component, OnInit, Input } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ButtonRendererEditComponent } from '../Common-Utils/components/button-renderer-edit/button-renderer-edit.component';
import { NotificationService } from '../Common-Utils/Services/notification.service'
import { CommonService } from '../Common-Utils/Services/common.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsaluserService } from '../msaluser.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UIErrorHandler } from '../Common-Utils/globalerrorhandler/UIErrorHandler';
import { AppError } from '../Common-Utils/globalerrorhandler/AppError';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modelfamily-spec-mapping',
  templateUrl: './modelfamily-spec-mapping.component.html',
  styleUrls: ['./modelfamily-spec-mapping.component.css']
})
export class ModelfamilySpecMappingComponent implements OnInit {
  faPlusSquare = faPlusSquare;
  form: FormGroup;
  searchForm: FormGroup;
  model_family = []; /* This variable holds model family value for dropdown*/
  showGrid: boolean = false;
  faSearch = faSearch;
  frameworkComponents: any;
  headerstring: string = environment.bannerTexts.ModelFamilySpecificationMappingText
  rowData: any;
  lastUpdateds:any;
  submitted = false;
  export:boolean=false;
  /* This variable refer to template driven form of search*/
  modelSearch: any = {
    model_family_search: "",
    specificationSearch: ""
  };
  @Input() name: string;
  modelfamilySpecMaping: string
  search_form_valid: boolean = true;
  clearButtonShow:boolean = false;
  private gridApi;
  private gridColumnApi;
  private editType;
  private defaultColDef;
  public _genericErrorHandler = null;
  constructor(
    private notifyService: NotificationService,
    private _commonService: CommonService,
    private formBuilder: FormBuilder,
    private msalService: MsaluserService,
    private router: Router
  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererEditComponent,
    }
    this.defaultColDef = {
      sortingOrder: ["asc", "desc"],
      stopEditingWhenGridLosesFocus: false,
      sortable: true,
      enableFilter: true,
      suppressKeyboardEvent: function (event) {
        if (!event.editing || event.event.code === "Enter")
          return true;
      }
    };
    this.editType = "fullRow";
  }
  columnDefs = [

    { headerName: 'Model Family', field: 'ModelFamily', resizable: true, minWidth: 125, flex: 2, editable: false },
    { headerName: 'Specification Internal Id', field: 'SpecificationID', minWidth: 100, resizable: true, editable: true },
    { headerName: 'Additional Notes', field: 'AdditionalNotes', minWidth: 100, resizable: true, editable: true },
    { headerName: 'Temperature', field: 'MinimumTemperature', resizable: true, minWidth: 90, maxWidth: 125, editable: true },
    { headerName: 'Priority', field: 'Priority', resizable: true, minWidth: 75, maxWidth: 90, editable: true },

    {
      headerName: 'Action',
      field: 'action', minWidth: 25,
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        cancelOtherRowEditors: this.cancelOtherRowEditors.bind(this),
        updateRowEditors: this.fnBindUpdateGrid.bind(this),//For edit--to get selected row values from button-renderededit component
        deleteRowEditors: this.fnBindDeleteMapping.bind(this)//For delete--to get selected row values from button-renderededit component

      },
    },

  ];

  ngOnInit(): void {
    this._genericErrorHandler = new UIErrorHandler();
    this.modelfamilySpecMaping = "modelfamilySpecMaping"
    this.fnBindMappingForm();
    this.searchForm = this.formBuilder.group({
      'model_family_search': '',
      'specificationSearch': ''
    });
    this.fnBindModelFamily();
    // Calling binding grid result oninit
    this.fnBindSearchGridResultAPI('');
    this.fnBindLastUpdatedByModel();
  }
  get f() { return this.form.controls; }
  fnBindMappingForm() {
    this.form = this.formBuilder.group({
      ModelFamilyId: ['', Validators.required],
      SpecificationId: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      Temperature: ['', Validators.required],
      Priority: ['', Validators.required],
      AdditionalNotes : ['','']
    });
  }

   /*Fn to fetch Last ModifiedBy and ModifiedDate of model */
   fnBindLastUpdatedByModel() {
    //Binding Last CreatedBy and Date from API  
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetLastModifiedModel?_tableName=ModelFamilySpecification', 'get', null).subscribe(
      res => {
        if (res != null) {          
          this.lastUpdateds = res["TBL_Result"]; 
        }
      }, (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      });
  }
  cancelOtherRowEditors(currentRowIndex) {
    //Renderer implies all rows of grid
    const renderers = this.gridApi.getCellRendererInstances();
    renderers.forEach(function (renderer) {
      if (!renderer._agAwareComponent.isNew &&
        currentRowIndex !== renderer._params.node.rowIndex) {
        renderer._agAwareComponent.onCancelClick();
      }
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }

  onCellClicked(params) {

    if (params.node.field !== 'action') {

      this.cancelOtherRowEditors(params.node.rowIndex);
    }
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const addMapData: any = {
      'ModelFamilyId': Number(this.form.controls["ModelFamilyId"].value),
      'SpecificationId': this.form.controls["SpecificationId"].value,
      'Temperature': String(this.form.controls["Temperature"].value),
      'AdditionalNotes': String(this.form.controls["AdditionalNotes"].value),
      'Priority': Number(this.form.controls["Priority"].value),
      'CreatedBy': this.msalService.getCurrentUserInfo()

    };


    this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/MapModelToSpecification', 'post', addMapData).subscribe(res => {

      if (res) {
        if (res == environment.constants.Success) {
          this.router.navigateByUrl('admin/modelfamily-spec-mapping');
          this.notifyService.showSuccess(environment.messageParams.msgModelFamilySpecificationMappingSuccess, "Success");
          this.form.reset();
          this.form.controls['ModelFamilyId'].setValue("", { onlySelf: true });
          this.submitted = false;
        }
        else {
          this.notifyService.showError(environment.messageParams.msgModelFamilySpecificationMappingDuplicate, "Error")
        }
      }
      else {
        this.notifyService.showError(environment.messageParams.msgSaveError, "Error")
      }

    },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      });
  }
  change() {
    this.min = 50;
  }
  /* Temperature Slider variable decleartion and function */
  autoTicks = true;
  disabled = false;
  invert = false;
  max = 50;
  min = -45;
  showTicks = true;
  step = 1;
  thumbLabel = true;
  temperature = 0;
  vertical = false;
  tickInterval = 1;

  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }
  formatLabel(value: number | null) {
    let fheitVal = ((value * 9 / 5) + 32).toFixed(1);
    return value + "°C" + " " + fheitVal + "°F";
  }
  /*  ---- Temperature function ends ---- */

  /* Specification Search function - Form values (this.modelSearch) */
  searchFunc() {
    if (this.modelSearch.specificationSearch == "" && this.modelSearch.model_family_search == "") {
      this.search_form_valid = false
      return;
    }
    this.clearButtonShow=true
    this.export=false;
    this.fnBindSearchGridResultAPI(this.modelSearch)
  }
  /*fnBindUpdateGrid -Calling Update API  */
  fnBindUpdateGrid(e) {
    const updateSpecificationtData: any = {
      'MapId': e.selectedData['Id'],
      'ModelFamilyId': Number(e.selectedData['ModelFamilyID']),
      'SpecificationId': e.selectedData['SpecificationID'],
      'Temperature': String(e.selectedData['MinimumTemperature']),
      'AdditionalNotes': String(e.selectedData["AdditionalNotes"]),
      'Priority': Number(e.selectedData['Priority']),
      'ModifiedBy': this.msalService.getCurrentUserInfo()

    };

    //Update API call
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/UpdateModelFamilySpecification', 'post', updateSpecificationtData).subscribe(res => {

      if (res) {

        if (res == environment.constants.Success) {
          this.notifyService.showSuccess(environment.messageParams.msgSpecificationUpdate, "Success")
        } else {
          this.notifyService.showError(environment.messageParams.msgSpecificationDuplicateError, "Error")
        }
        //Reloading grid after duplicate or success
        this.fnBindSearchGridResultAPI('');
      }
      else {
        this.notifyService.showError(environment.messageParams.msgUpdateError, "Error")
      }

    },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
        this.searchFunc()
      }
    )

  }
  fnBindDeleteMapping(e) {
    //Getting ID to pass to Delete API 
    let _mfid = e[0]['Id'];
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/DeleteModelFamilySpecification?id=' + _mfid, 'post', null).subscribe(res => {

      console.log('res-del', res);
      if (res) {
        if (res == environment.constants.Success) {
          this.notifyService.showSuccess(environment.messageParams.msgModelFamilySpecificationMappingDeleted, "Success")
          //Reload the grid

        } else { this.notifyService.showError(environment.messageParams.msgDeleteError, "Error") }
        //Reloading grid after duplicate or success
        this.fnBindSearchGridResultAPI('');
      }
      else {
        this.notifyService.showError(environment.messageParams.msgDeleteError, "Error")
      }
    },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      })
  }
  /*Binding ModelFamily Dropdown from API */
  fnBindModelFamily() {
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetModelFamily', 'get', null).subscribe(
      res => {
        if (res != null) {
          this.model_family = res["TBL_Result"];
        }
      },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      }
    );

  }
  /*load grid values based on search*/
  fnBindSearchGridResultAPI(_searchItems) {
    this.search_form_valid = true
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetModelFamilySpecification?_specification=' + (_searchItems.specificationSearch == undefined ? '' : _searchItems.specificationSearch) + '&_modelFamily=' + (_searchItems.model_family_search == undefined ? '' : _searchItems.model_family_search), 'get', null).subscribe(
      res => {
        if (res != null) {
          this.rowData = res["TBL_Result"];
          this.showGrid = true;
        }
      },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      }
    );
  }

  refreshSearchGrid(){
    console.log("search clear")
    this.modelSearch.specificationSearch="";
    this.modelSearch.model_family_search=""
    this.fnBindSearchGridResultAPI('')
    this.clearButtonShow=false;
    this.export=false;
  }

  exportAsXLSX(){
    this.export=true;
    const SpecModelList = [];
    this.rowData.forEach((item) => {
        SpecModelList.push({
          'Model Family': item.ModelFamily,
          'Specification Internal ID': item.SpecificationID,
          'Additional Notes': item.AdditionalNotes,
          Temperature: item.MinimumTemperature,
          Priority: item.Priority
        });
      });
    this.msalService.exportAsExcelFile(SpecModelList, 'Specification-ModelFamily');
  }

  DeleteAll(){
    Swal.fire({
      text: environment.messageParams.msgDeleteConfirmationModelSpecification,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: environment.messageParams.msgDeleteConfirmationButton
    }).then((result) => {      
    if (result.isConfirmed){   
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/DeleteAllModelSpecification' , 'post', null).subscribe(
      res => {
        console.log('res-del', res);
        if (res == environment.constants.Success) {
          this.notifyService.showSuccess(environment.messageParams.msgModelSpecificationDelete, "Success")      
        //Reloading grid after duplicate or success
          this.fnBindSearchGridResultAPI('');
      }
      else {
        this.notifyService.showError(environment.messageParams.msgDeleteError, "Error")
      }
    },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
        this.searchFunc()
      }
    ); 
   }
   });
  }

}
