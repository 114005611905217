<app-banner [headerstring]="headerstring"></app-banner>

<div class="banner-small banner-md banner-xl-large">
  <div class="container">
    <div class="row wide-gutters-xl">
      <div class="col-md-3">
        <app-sidebar [data]="model"></app-sidebar>

        <div class="d-none d-md-block">
        </div>
      </div>
      <div class="col-md-9">
        <div class="divide-10 divide-md-0"></div>
        <h3 class="h1 headline-large">Model Number</h3>

        <div class="m-top-2em">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="input-group fullwidth">
                <input [(ngModel)]="searchModel" class="form-control" placeholder="Search..." />
                <div class="input-group-append">
                  <button class="btn btn-info" type="button"
                    [attr.title]="(searchModel=='') ?'Please Enter text' : 'Search Model' " [disabled]="searchModel==''"
                    (click)="fetchResults()"
                    [ngStyle]="{'cursor':(searchModel=='') ? 'not-allowed':'pointer','background':(searchModel=='') ? '#A9A9A9':'#007bbf'}">
                    <fa-icon [icon]="faSearch"><span class="sr-only">Search</span></fa-icon>
                  </button>
                  <button *ngIf="clearButtonShow" class="btn btn-info" type="button" title="Clear"
                    (click)="refreshSearchGrid()">
                    <fa-icon [icon]="faEraser"><span class="sr-only">Clear</span></fa-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <button class="btn  btn-info fullwidth" data-toggle="modal" data-target="#model_number" title="Add Model"
                (click)="openModel()">
                <fa-icon [icon]="faPlusSquare"></fa-icon> Model Number
              </button>
            </div>
            <div class="banner-margin-medium-top col-lg-12 col-md-12 col-sm-12" *ngIf="showGrid">

              <ag-grid-angular  class="ag-theme-alpine vertical-scroll" pagination=true
                paginationPageSize=10 enableRangeSelection=true, [columnDefs]="columnDefs" [rowData]="rowData"
                [editType]="editType" [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)"
                (cellClicked)="onCellClicked($event)" [defaultColDef]="defaultColDef" [immutableColumns]="true"
                suppressClickEdit>
              </ag-grid-angular>

            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div> 

<!-- Modal -->
<div class="modal fade" #modelno id="model_number" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLongTitle">Add Model</h2>
        <button type="button" (click)="closeModel()" #closeModelNumber class="close" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label>Model</label>
            <input type="text" formControlName="modelNumber" maxlength="50" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.modelNumber.errors }" />
            <div *ngIf="submitted && f.modelNumber.errors" class="invalid-feedback">
              <div *ngIf="f.modelNumber.errors.required">Model Number required</div>
              <div *ngIf="f.modelNumber.errors.pattern">Enter a valid Model Nmae</div>
            </div>
          </div>
          <div class="form-group">
            <label>Model Tag</label>
            <input type="text" formControlName="modelTag" maxlength="50" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.modelTag.errors }" />
            <div *ngIf="submitted && f.modelTag.errors" class="invalid-feedback">
              <div *ngIf="f.modelTag.errors.required">Model Tag required</div>
              <div *ngIf="f.modelTag.errors.pattern">Enter a valid Model Tag</div>
            </div>
          </div>
          <div class="form-group">
            <label>Description 1</label>
            <textarea formControlName="description1" maxlength="500" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.description1.errors }" rows="3"></textarea>
            <div *ngIf="submitted && f.description1.errors" class="invalid-feedback">
              <div *ngIf="f.description1.errors.required">Description1 is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Description 2</label>
            <textarea formControlName="description2" maxlength="200" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.description2.errors }" rows="3"></textarea>
            <div *ngIf="submitted && f.description2.errors" class="invalid-feedback">
              <div *ngIf="f.description2.errors.required">Description2 is required</div>
            </div>
          </div>

          <div class="form-group">
            <button [disabled]="loading" title="Save Model" class="btn btn-info">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>