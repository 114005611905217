import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { CommonService } from '../../Common-Utils/Services/common.service';
import { AppError } from '../../Common-Utils/globalerrorhandler/AppError';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Model } from '../../Common-Utils/datamodels/Model';
import { environment } from 'src/environments/environment';
import { UIErrorHandler } from '../../Common-Utils/globalerrorhandler/UIErrorHandler';
import { NotificationService } from '../../Common-Utils/Services/notification.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { faSearch, faPrint } from '@fortawesome/free-solid-svg-icons';
import { BroadcastService, MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.css'],
})
export class InputSearchComponent implements OnInit {
  @Output('SearchResponse') SearchResponse = new EventEmitter();
  @Output() SearchType = new EventEmitter<number>();
  myControl: FormControl = new FormControl();
  filteredOptions: Observable<Model[]>;
  filteredSnoOptions: Observable<string[]>;

  isShowSearchGrid: boolean = false;
  faSearch = faSearch;
  faPrint = faPrint;
  constructor(
    private httpClient: HttpClient,

    private _commonService: CommonService,
    private notifyService: NotificationService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router,
    private authService: MsalService
  ) { }

  public loggedIn = false;

  selectedModel_family = 0;
  selectedModelNumber = 0;
  selectedSerialNumber = 0;
  // This parameter holds the search result data
  resultedFluids: any = [];
  // This parameter holds the input for search
  public _genericErrorHandler = null;

  model: any = {
    selectedMarket: '',
    selectedModelNumber: '',
    selectedModel_family: '',
    serialNo: '',
  };
  serialModel: any = {};
  model_family_data: any = [];
  model_number_data: any = [];
  serial_number_data: any = [];
  market_data: any = [];
  options: boolean;
  type: number;
  searchText = '';
  _selectedMarketID: number;
  _selectedModelID: number | null;
  serial_number = [];
  model_number: Model[] = [];
  model_family = [];
  model_number_information = [];
  show_model_number_information: boolean = false;
  serial_number_information = [];
  show_serial_number_information: boolean = false;
  show_no_product_found = false;

  filteredValues = [];
  showMarket: boolean = false;
  enableButton: boolean = false;

  //parameters for slider-temperature--start
  autoTicks = true;
  disabled = false;
  invert = false;
  max = 50;
  min = -45;
  step = 1;
  thumbLabel = true;
  temperature = 0;
  vertical = false;

  formatLabel(value: number | null) {
    let fheitVal = ((value * 9) / 5 + 32).toFixed(0);
    return value + '°C' + ' ' + fheitVal + '°F';
  }
  //parameters for slider-temperature--end

  //For market dropdown change, if there is more than one market in DB
  onMarketChange(MKTID: number) {
    this._selectedMarketID = MKTID;
    this.fnBindModelFamily();
  }

  onSelectModelNumber(event: any) {
    this.enableButton = true;
    this._selectedModelID = event.option.value['ModelID'];
    this.fnBindModelNumberInfo(event.option.value['ModelID']);
  }

  onKeyUp(event) {
    this.searchText = event.target.value;
    if (this.searchText.length > 2) {
      this.fnBindModel();
      this.show_model_number_information = false;
      this.isShowSearchGrid = false;
      this.show_no_product_found = false;
      this.enableButton = false;
    } else {
      this.model_number = [];
    }
  }

  onKeyUpSerialNo(event) {
    this.show_serial_number_information = false;
    this.isShowSearchGrid = false;
    this.show_no_product_found = false;
    this.SearchResponse.emit('reset');
  }
  onKeyUpModelFamily(event) {
    this.enableButton = true;
  }
  displayFn(z: Model): string {
    // return z && z.ModelTag ? z.ModelTag : '';
    return z && z.ModelName ? z.ModelName : '';
  }

  ngOnInit() {
    //Set Model family as a default search
    this.type = 0;
    //Bind ModelFamily,ModelNumber,Serialnumber from API
    this.fnBindMarket();

    this.fnBindModelFamily();
    this._genericErrorHandler = new UIErrorHandler();
    this.onItemChange();
    this.loggedIn = !!this.authService.getAccount();
  }

  onSearchFluid(buttontype) {
    if (buttontype === 'search') {
      //To show grid on search result
      this.ngxLoader.start();
      this.isShowSearchGrid = true;

      //API call to get the Specifications

      let _temp =
        this.model.temperature != undefined ? String(this.model.temperature) : 0;
      let _mfID =
        this.model.selectedModel_family != undefined
          ? this.model.selectedModel_family
          : null;
      let _mId =
        this._selectedModelID != undefined ? this._selectedModelID : null;
      let _slNo = this.model.serialNo != undefined ? this.model.serialNo.replace(/[^\w\s]/gi, '') : '';

      let _url = '';

      if (_mId != null) {
        _url = '&_modelId=' + _mId;
      } else if (_mfID != null && _mfID != '') {
        _url = '&_modelFamilyId=' + _mfID;
      } else if (_slNo != '') {
        _url = '&_serialnumber=' + _slNo;
      }

      this._commonService
        .fnCallWebAPI(
          environment.baseUrl +
          'Search/GetSearchResults?_tempetaure=' +
          _temp +
          _url,
          'get',
          null
        )
        .subscribe(
          (res) => {
            this.resultedFluids = [];
            if (Object.keys(res['lstResult']).length > 0) {
              //Binding response from API to resultedFluids
              this.resultedFluids = res['lstResult'];
              this.SearchResponse.emit(res['lstResult']);
              //To show grid on search result
              this.isShowSearchGrid = true;
            } else {
              this.SearchResponse.emit('nodata');
            }
            this.ngxLoader.stop();
          },
          (error: AppError) => {
            this._genericErrorHandler.handleError(
              error,
              this.notifyService,
              this.router
            );
            this.ngxLoader.stop();
          }
        );
    }
    else if (buttontype === 'print') {
      window.print();
    }
  }

  openDoc(pdfUrl: string) {
    window.open(pdfUrl, '_blank', '');
  }

  onSearchChange(val) {
    this.type == val;
    this.onItemChange();
  }

  onItemChange() {
    this.model.selectedMarket = '';
    this.model.selectedModelNumber = '';
    this.model.selectedModel_family = '';
    this.myControl.reset('', { emitEvent: false });

    //  this.myControl.setValue('',{emitEvent:false});
    this.model.temperature = 0;
    this.show_model_number_information = false;
    this.show_serial_number_information = false;
    this.show_no_product_found = false;
    this.isShowSearchGrid = false;
    this.model.serialNo = '';
    this._selectedModelID = null;
    this.enableButton = false;
    // this.resultedFluids=[];
    this.SearchType.emit(this.type);
    this.SearchResponse.emit('reset');
  }
  /* fnBindModelFamilyInfo : Function to display the Model Information*/
  fnBindModelNumberInfo(MID) {
    this._commonService
      .fnCallWebAPI(
        environment.baseUrl + 'Search/GetModelByModelNumber?searchtext=' + MID,
        'get',
        null
      )
      .subscribe(
        (res) => {
          if (res['TBL_Result'].length > 0) {
            this.model_number_information = res['TBL_Result'][0];
            this.show_model_number_information = true;
          }
        },
        (error: AppError) => {
          this._genericErrorHandler.handleError(
            error,
            this.notifyService,
            this.router
          );
          this.ngxLoader.stop();
        }
      );
  }
  /* fnBindSerialNoInfo : Function to display the Serial Information*/
  fnBindSerialNoInfo(value) {
    this.show_no_product_found = false;
    this.enableButton = false;
    if (value != '' && value != undefined) {
      this.ngxLoader.start();
      value = value.replace(/[^\w\s]/gi, '');
      this._commonService
        .fnCallWebAPI(
          environment.baseUrl +
          'Search/GetModelBySerialNumber?searchtext=' +
          value,
          'get',
          null
        )
        .subscribe(
          (res) => {
            this.ngxLoader.stop();
            if (res != null) {
              this.serial_number_information = res['TBL_Result'][0];
              if (this.serial_number_information != undefined) {
                this.show_serial_number_information = true;
                this.enableButton = true;
              } else {
                this.show_no_product_found = true;
                this.enableButton = false;
              }
            }
          },
          (error: AppError) => {
            this.ngxLoader.stop();
            this._genericErrorHandler.handleError(
              error,
              this.notifyService,
              this.router
            );
            this.ngxLoader.stop();
          }
        );
    }
  }
  fnBindModelFamily() {
    //Binding ModelFamily Dropdown from API
    let searchUrl = '';
    if (this._selectedMarketID == undefined) {
      searchUrl = 'Search/GetModelFamily';
    } else {
      searchUrl = 'Search/GetModelFamily?inputvalue=' + this._selectedMarketID;
    }
    this._commonService
      .fnCallWebAPI(environment.baseUrl + searchUrl, 'get', null)
      .subscribe(
        (res) => {
          if (res != null) {
            this.model_family = res['TBL_Result'];
          }
        },
        (error: AppError) => {
          this._genericErrorHandler.handleError(
            error,
            this.notifyService,
            this.router
          );
          this.ngxLoader.stop();
        }
      );
  }

  fnBindMarket() {
    //Binding Market Dropdown from API

    this._commonService
      .fnCallWebAPI(environment.baseUrl + 'Search/GetMarket', 'get', null)
      .subscribe(
        (res) => {
          if (res != null) {
            this.market_data = res['TBL_Result'];

            if (this.market_data.length > 0) {
              if (this.market_data.length > 1) {
                this.showMarket = true;
              } else {
                this._selectedMarketID = this.market_data[0].Id;
                this.fnBindModelFamily();
              }
            }
          }
        },
        (error: AppError) => {
          this._genericErrorHandler.handleError(
            error,
            this.notifyService,
            this.router
          );
          this.ngxLoader.stop();
        }
      );
  }

  fnBindModel() {
    //Binding ModelNumber Dropdown from API

    var newText = '';
    if (this.searchText) {
      newText = this.searchText.replace(/[^\w\s]/gi, '');
    }

    this._commonService
      .fnCallWebAPI(
        environment.baseUrl + 'Search/GetModelOnSearch?searchtext=' + newText,
        'get',
        null
      )
      .subscribe(
        (res) => {
          if (res != null) {
            this.model_number = res['TBL_Result'];
            //Binding for autocomplete for modelNumber
            this.filteredOptions = this.myControl.valueChanges.pipe(
              startWith(''),
              map((x) => (typeof x === 'string' ? x : x.ModelName)),
              map((ModelName) =>
                ModelName ? this._filter(ModelName) : this.model_number.slice()
              )
            );
          }
        },
        (error: AppError) => {
          this._genericErrorHandler.handleError(
            error,
            this.notifyService,
            this.router
          );
          this.ngxLoader.stop();
        }
      );
  }
  private _filter(name: string): Model[] {
    const filterValue = name.toLowerCase();
    return this.model_number.filter(
      (option) => option.ModelTag.toLowerCase().indexOf(filterValue) === 0
    );
  }

  omit_special_char(e) {
    var k;
    document.all ? (k = e.keyCode) : (k = e.which);
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
} //close of export
