<div class="banner--interior banner--market background-cover header-margin" style="background-image:url(https://www.dana.com/contentassets/e1a8809858844434ad8cf7648854d601/banner-abstracts-1.jpg?w=1199&quality=60&format=jpg);">
    <!-- background-image: url(https://www.dana.com/contentassets/e1a8809858844434ad8cf7648854d601/banner-abstracts-1.jpg?w=1600&quality=60&format=jpg);/* height: 284px; */ -->
    <div class="container">
        <div class="title banner" style="height: 0;">
            <h1 class="h1 banner-heading">{{headerStringValue}}</h1>
            <span class="color">
                <span class="flavor"></span>
            </span>
        </div>
    </div>
  </div>