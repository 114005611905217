import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-button-renderer-edit',
  template: `
    <button type="button" *ngIf="isNew == true" (click) = "onEditClick()" data-action-type="view" class="btn btn-default">
    <fa-icon title="Edit" style="color:#117a8b" [icon]="faEdit"><span class="sr-only">Edit</span></fa-icon>
             </button>

             <button type="button"  *ngIf="isNew == false" (click) = "onUpdateClick()" data-action-type="view" class="btn btn-default">
             <fa-icon title="Save" style="color:green" [icon]="faSave"><span class="sr-only">Save</span></fa-icon>
             </button>

             <button type="button" *ngIf="isNew == false" (click) = "onCancelClick()" data-action-type="view" class="btn btn-default">
             <fa-icon title="Cancel" style="color:red" [icon]="faWindowClose"><span class="sr-only">Cancel</span></fa-icon>
             </button>

             <button  type="button" style="color:red"  
            *ngIf="isNew == true"
            
             data-action-type="remove" class="btn btn-default" (click)="onDeleteClick()"
            >
            <fa-icon title="Delete" [icon]="faTrashAlt"><span class="sr-only">Delete</span></fa-icon>
            </button>
            `,
  styleUrls: ['./button-renderer-edit.component.css']
})
export class ButtonRendererEditComponent implements ICellRendererAngularComp {
  faEdit = faEdit;
  faWindowClose = faWindowClose;
  faTrashAlt = faTrashAlt;
  faSave = faSave;
  confirmClicked = false;
  cancelClicked = false;
  constructor() {
    this.isNew = true;
  }

  public params: any;
  public isNew: any;
  public previousData: any;

  agInit(params: any): void {
    this.params = params;
  }

  public invokeParentMethod() {
    this.params.context.componentParent.methodFromParent(`Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`)
  }

  refresh(): boolean {
    return false;
  }

  onEditClick() {
    const index = this.params.node.rowIndex;
    this.params.cancelOtherRowEditors(index);
    this.isNew = false;
    this.previousData = JSON.parse(JSON.stringify(this.params.node.data));
    console.log(this.params.data)
    let cols = this.params.columnApi.getAllGridColumns();
    console.log("cols", cols)
    let firstCol = {
      "colId": ""
    }
    if (cols) {
      firstCol = cols[0];
    }
    let rowIndex = this.params.node.rowIndex;
    console.log("firstCol", firstCol)
    this.params.api.setFocusedCell(rowIndex, firstCol.colId);
    this.params.api.startEditingCell({
      rowIndex: rowIndex,
      colKey: cols[0]
    });
  }

  onUpdateClick() {
    this.isNew = true;
    let obj: any = {};
    obj.type = "update";
    this.params.api.stopEditing();

    const index = this.params.node.rowIndex;
    this.params.cancelOtherRowEditors(index);
    obj.selectedData = JSON.parse(JSON.stringify(this.params.node.data));
    console.log(obj.selectedData)
    this.params.updateRowEditors(obj);
    //obj.selectedData = [this.params.data];
    // update logic ....
  }

  public onCancelClick() {
    this.isNew = true;
    this.params.node.setData(this.previousData);
    this.params.api.stopEditing(true);
  }

  onDeleteClick() {
    let componentName = this.params.node.gridOptionsWrapper.gridOptions.defaultColDef.component;
    let confirmationMessage = '';
    let fieldName = '';
    switch (componentName) {
      case environment.bannerTexts.marketTxt: {
        fieldName = this.params.node.data['Name'];
        confirmationMessage = environment.messageParams.msgDeleteConfirmationMasters.replace('[[Value]]', fieldName);
        break;
      }
      case environment.bannerTexts.modelFamilyText: {
        fieldName = this.params.node.data['ModelFamilyName']
        confirmationMessage = environment.messageParams.msgDeleteConfirmationMasters.replace('[[Value]]', fieldName);
        break;
      }
      case environment.bannerTexts.modelText: {
        fieldName = this.params.node.data['ModelName']
        confirmationMessage = environment.messageParams.msgDeleteConfirmationMasters.replace('[[Value]]', fieldName);
        break;
      }
      default: {
        confirmationMessage = environment.messageParams.msgDeleteConfirmationMapping;
        break;
      }
    }

    Swal.fire({
      text: confirmationMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: environment.messageParams.msgDeleteConfirmationButton
    }).then((result) => {
      if (result.isConfirmed) {
        const selectedData = [this.params.node.data];
        this.params.deleteRowEditors(selectedData);
      }
    })

    //this.params.api.applyTransaction({ remove: selectedData });
  }
}
