import { Component, OnInit, ViewChild } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { ButtonRendererEditComponent } from '../Common-Utils/components/button-renderer-edit/button-renderer-edit.component';
import { environment } from 'src/environments/environment';
import { CommonService } from '../Common-Utils/Services/common.service';
import { NotificationService } from '../Common-Utils/Services/notification.service'
import { MsaluserService } from '../msaluser.service';
import { UIErrorHandler } from '../Common-Utils/globalerrorhandler/UIErrorHandler';
import { AppError } from '../Common-Utils/globalerrorhandler/AppError';
import * as $ from 'jquery';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.css']
})
export class MarketComponent implements OnInit {
  faPlusSquare = faPlusSquare;
  faSearch = faSearch;
  faEraser=faEraser;
  searchModel: any;
  market: string;
  headerstring: string = environment.bannerTexts.marketTxt
  showGrid: boolean = false
  form: FormGroup;
  id: number;
  isAddMode: boolean = true;
  loading = false;
  submitted = false;
  @ViewChild('marketPopup') marketPopup;
  @ViewChild('closeMarket') closeMarket;
  @ViewChild('FormGroupDirective') formGroupDirective: FormGroupDirective;
  
  frameworkComponents: any;  
  private gridApi;
  private gridColumnApi;
  private editType;
  private defaultColDef;
  public _genericErrorHandler = null;
  clearButtonShow:boolean = false;
  constructor(

    private formBuilder: FormBuilder,
    private router: Router,
    private _commonService: CommonService,
    private msalService: MsaluserService,
    private notifyService: NotificationService

  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererEditComponent,
    }
    this.defaultColDef = {
      sortingOrder: ["asc", "desc"],
      component : environment.bannerTexts.marketTxt,
      stopEditingWhenGridLosesFocus: false,
      sortable: true,
      enableFilter: true,
      suppressKeyboardEvent: function (event) {
        if (!event.editing || event.event.code === "Enter")
          return true;
      }
    };
    this.editType = "fullRow";
  }
  columnDefs = [

     { headerName: 'Market Name', field: 'Name', minWidth: 200,maxWidth: 300,flex: 4,resizable: true, editable: true },
    { headerName: 'Description', field: 'Description', resizable: true, minWidth: 200,maxWidth: 300, flex: 4, editable: true,tooltipField: 'Description' },
    {
      headerName: 'Status', field: 'Status', minWidth: 75, maxWidth: 100, editable: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        //values: ['Active', 'Inactive']
        values:environment.constants.statusDropdownValues  //For Status dropdown
      }
    },
    {
      headerName: 'Action',
      field: 'action', minWidth: 100, maxWidth: 125,
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        cancelOtherRowEditors: this.cancelOtherRowEditors.bind(this),
        updateRowEditors: this.fnBindUpdateGrid.bind(this),//For edit--to get selected row values from button-renderededit component
        deleteRowEditors: this.fnDeleteMarket.bind(this)//For delete--to get selected row values from button-renderededit component

      },
    },


  ];
  rowData: any;


  ngOnInit(): void {
    this.market = "market"
   this.createMarketForm();
    this.msalService.initializeAuthentication();
    this._genericErrorHandler = new UIErrorHandler();
    //Calling binding grid result oninit
    this.searchModel='';
    this.fetchResults();
  }

  get f() { return this.form.controls; }
  fnNewForm() {
    this.form.reset();
  }
  /* createMarketForm - To create a new form */
  createMarketForm(){
    this.form = this.formBuilder.group({
      market: ['', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      description: [''],
      active: ['']
    });
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid 
    
    if (this.form.invalid) {    
      return;
    }
  
    this.loading = true;
    if (this.isAddMode) {
      this.CreateMarket()
    }
  }

  cancelOtherRowEditors(currentRowIndex) {
    //Renderer implies all rows of grid
    const renderers = this.gridApi.getCellRendererInstances();
    renderers.forEach(function (renderer) {
      if (!renderer._agAwareComponent.isNew &&
        currentRowIndex !== renderer._params.node.rowIndex) {
        renderer._agAwareComponent.onCancelClick();
      }
    });
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }

  onCellClicked(params) {    
    if (params.node.field !== 'action') {
      this.cancelOtherRowEditors(params.node.rowIndex);
    }
  }
  /*CreateMarket - To create new Market*/
  private CreateMarket() {
    this.loading = false;    
    const addMarketData: any = {
      'Name': (this.form.controls["market"].value).trim(),
      'Description': this.form.controls["description"].value,
      'Status': true,
      'CreatedBy': this.msalService.getCurrentUserInfo()
    };
    
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Maintenance/InsertMarket', 'post', addMarketData).subscribe(res => {
      console.log('Market created successfully!', res);   
      if(res){
        if(res==environment.constants.Success){
          this.notifyService.showSuccess(environment.messageParams.msgMarketAdd,"Success")
          this.closeModel();
          this.router.navigateByUrl('/admin'); 
          this.searchModel = '';
          
        }else{
          this.notifyService.showError(environment.messageParams.msgMarketDuplicateError,"Error")
          this.searchModel = '';
        }
        this.showGrid = false;
      }else{
        this.notifyService.showError(environment.messageParams.msgSaveError, "Error")
      }  
    
    },
    (error: AppError) => {
      this._genericErrorHandler.handleError(error, this.notifyService, this.router);
    })
  }
  /*fnBindUpdateGrid -Calling Update API  */
  fnBindUpdateGrid(e) {
    console.log("Update grid value", e)
    if (e.selectedData['Name'] == '' || e.selectedData['Name'] == undefined) {
      this.notifyService.showError(environment.messageParams.msgMarketNameEmpty, "Error")
      this.fetchResults()
      return;
    }
    const updateMarketData: any = {
      'Id': e.selectedData['Id'],
      'Name': e.selectedData['Name'],
      'Description': e.selectedData['Description'],
      'Status': e.selectedData['Status'] == 'Active' ? true : false,
      'ModifiedBy': this.msalService.getCurrentUserInfo()

    };
    console.log('updateMarketData', updateMarketData)
    //Update API call
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Maintenance/UpdateMarket', 'post', updateMarketData).subscribe(res => {
      console.log('Updated successfully!', res);
      if (res) {
        console.log("Update Result", res)
        if (res == environment.constants.Success) {
          this.notifyService.showSuccess(environment.messageParams.msgMarketUpdate, "Success")
        } else {
          this.notifyService.showError(environment.messageParams.msgMarketDuplicateError, "Error")
        }
        //Reloading grid after duplicate or success
        this.fetchResults()
      }
      else {
        this.notifyService.showError(environment.messageParams.msgUpdateError, "Error")
      }

    },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
        this.fetchResults()
      }
    )

  }
  //Calling Delete API
  fnDeleteMarket(e) {
    //Getting ID to pass to Delete API 
    let _mfid = e[0]['Id'];    
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Maintenance/DeleteMarket?id=' + _mfid, 'post', null).subscribe(res => {      
      if (res) {
        if (res == environment.constants.Success) {
          //Reloading grid after delete successful
          this.fetchResults();
          this.notifyService.showSuccess(environment.messageParams.msgMarketDeleted, "Success")
        } else if (res == environment.constants.MappingExists) {
          this.notifyService.showWarning(environment.messageParams.msgMarketDeleteMappingExists, "Warning")
        }
      }
      else {
        this.notifyService.showError(environment.messageParams.msgDeleteError, "Error")
      }
    },
    (error: AppError) => {
      this._genericErrorHandler.handleError(error, this.notifyService, this.router);
    })
  }
  /* To load grid data based on search */
  fetchResults() {  
    
      this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetMarket?getAll=true&searchtext=' + this.searchModel, 'get', null).subscribe(
        res => {          
          if (res != null) {
            this.rowData = res["TBL_Result"];
            this.showGrid = true; 
            if(this.searchModel!=''){
              this.clearButtonShow=true; 
            }      

          }
        },
        (error: AppError) => {
          this._genericErrorHandler.handleError(error, this.notifyService, this.router);
        }
      );   
   
  }

  /*Model popup functions*/
  openModel() {
    this.submitted =false
    this.form.reset()  
    }
  closeModel() {
    this.closeMarket.nativeElement.click();
    $('.modal-backdrop').remove();
  }
  /*Model popup functions Ends*/

  refreshSearchGrid(){
    console.log("search clear")
    this.searchModel='';
    this.fetchResults();
    this.clearButtonShow=false;
    
    
  }
}

