import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-button-renderer',
  template: `
  <button style="color:red"
   type="button" class="btn btn-link " (click)="onDeleteClick($event)"
   (confirm)="onClick($event)"
   >
   <fa-icon [icon]="faTrashAlt"><span class="sr-only">Delete</span></fa-icon>
   </button>
    `
})
export class ButtonRendererDeleteComponent implements ICellRendererAngularComp {

  constructor() { }
  public confirmDelete = environment.messageParams.msgDeleteConfirmationMapping;
  faTrashAlt = faTrashAlt;
  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data,
      }
      this.params.onClick(params);

    }
  }
  onDeleteClick($event) {
    Swal.fire({
      // title: 'Are you sure?',
      text: environment.messageParams.msgDeleteConfirmationMapping,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: environment.messageParams.msgDeleteConfirmationButton
    }).then((result) => {
      if (result.isConfirmed) {
        this.onClick($event);
      }
    })

    //this.params.api.applyTransaction({ remove: selectedData });
  }

}
