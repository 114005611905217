import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input() data;
  @ViewChild('menuExpand') menuExpand;
  @ViewChild('ulExpand') ulExpand;
  constructor(
    private router: Router
  ) {
    
   }
  public listElements: Array<{ text: string, link: string,value:string }> = [
    { text: "Market", link: "/admin/market",value: 'market'},
    { text: "Model Family", link: "/admin/model-family" ,value: 'modelfamily'},
    { text: "Model Number", link: "/admin/model" ,value: 'model'},
    { text: "Market - Model Family Mapping", link: "/admin/market-family-mapping",value: 'marketfamilyMaping' },
    { text: "Serial n° - Model Mapping", link: "/admin/model-serial-mapping",value: 'modelserialMaping' },
    { text: "Model  - Model Family Mapping", link: "/admin/model-family-mapping",value: 'modelfamilyMaping' },
    { text: "Model Family – Specification Mapping", link: "/admin/modelfamily-spec-mapping",value: 'modelfamilySpecMaping' },
    { text: "File Upload", link: "admin/file-upload",value: 'fileupload' }
    
  ];
  selectedItem:string="";
  listClick(event,value,link) {   
    this.selectedItem = value;  
    event.target.addClass="active"
    this.router.navigate([link]); 
}

activateClass(subModule){
  subModule.active = !subModule.active;    
}
  ngOnInit(): void {
    this.selectedItem = this.data
   
  }
  ngAfterViewInit(){
    console.log('has class active', this.menuExpand.nativeElement.classList.contains('active'))
  }
  menuExpandActive(){
    if(!this.menuExpand.nativeElement.classList.contains('active')){
      this.menuExpand.nativeElement.classList.add('active')
      this.ulExpand.nativeElement.classList.add('active')
    }else{
      this.menuExpand.nativeElement.classList.remove('active')
      this.ulExpand.nativeElement.classList.remove('active')
    }
  }
}
