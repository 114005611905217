import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap, startWith } from 'rxjs/operators';
import { ButtonRendererDeleteComponent } from '../Common-Utils/components/button-renderer-delete/button-renderer-delete.component'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../Common-Utils/Services/common.service';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../Common-Utils/Services/notification.service';
import { MsaluserService } from '../msaluser.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Model } from '../Common-Utils/datamodels/Model';
import { UIErrorHandler } from '../Common-Utils/globalerrorhandler/UIErrorHandler';
import { AppError } from '../Common-Utils/globalerrorhandler/AppError';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Console } from 'console';

@Component({
  selector: 'app-model-serial-mapping',
  templateUrl: './model-serial-mapping.component.html',
  styleUrls: ['./model-serial-mapping.component.css']
})
export class ModelSerialMappingComponent implements OnInit {
  faPlusSquare = faPlusSquare;
  form: FormGroup;
  //For autocomplete
  myControl: FormControl = new FormControl();
  myControl1: FormControl = new FormControl();

  filteredOptions: Observable<Model[]>;
  filteredSnoOptions: Observable<string[]>;
  searchFilteredOptions: Observable<string[]>;
  serial_number = [];
  model_number = [];
  model_number_search = [];
  //auto complete-end

  production_date: string;
  faSearch = faSearch;
  serialmodelMaping: string;
  frameworkComponents: any;
  showGrid: boolean = false;
  headerstring: string = environment.bannerTexts.ModelSerialNumberMappingText
  searchForm: FormGroup;
  rowData: any;
  search_form_valid: boolean = true;
  submitted = false;
  clearButtonShow:boolean = false;
  export:boolean=false;
  model: any = {
    SerialNumber: '',
    selectedModel: '',
    ProductionDate: ''
  };
  lastUpdateds:any;
  searchText = '';
  searchText1 = '';
  public _genericErrorHandler = null;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private _commonService: CommonService,
    private notifyService: NotificationService,
    private formBuilder: FormBuilder,
    private msalService: MsaluserService,
    private router: Router
  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererDeleteComponent,
    }
  }


  columnDefs = [

    { headerName: 'Model Number', field: 'ModelName', resizable: true, maxWidth : 300 },
    { headerName: 'Serial Number', field: 'SerialNumber', resizable: true, minWidth: 250, maxWidth: 300, flex: 2 },
    { headerName: 'Production Date', field: 'ManufactureDate', resizable: true, maxWidth: 150 },

    {
      headerName: 'Action',
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.fnBindDeleteMapping.bind(this),
        label: 'Click 1'
      }
    },
  ];
  ngOnInit(): void {
    this.serialmodelMaping = "modelserialMaping";
    this.fnBindMappingForm();
    this.searchForm = this.formBuilder.group({
      'search_model_number': '',
      'search_serial_number': ''
    });    
    
    this._genericErrorHandler = new UIErrorHandler();

    //Calling binding grid result oninit
    this.fnBindSearchGridResultAPI('');
    this.fnBindLastUpdatedByModel();
  }
  get m() { return this.form.controls; }
  fnBindMappingForm() {
    this.form = this.formBuilder.group({
      SerialNumber: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      ProductionDate: ['', [Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      selectedModel: ['', Validators.required]
    });
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    const addMapData: any = {
      'ModelId': this.model.selectedModel,
      'SerialNumber': this.form.controls["SerialNumber"].value,
      'ProductionDate': this.form.controls["ProductionDate"].value,
      'CreatedBy': this.msalService.getCurrentUserInfo()

    };


    this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/MapSerialNumberToModel', 'post', addMapData).subscribe(res => {

      if (res) {
        if (res == environment.constants.Success) {
          this.router.navigateByUrl('admin/model-serial-mapping');
          this.notifyService.showSuccess(environment.messageParams.msgModelSerialNumberMappingSuccess, "Success");

          this.fnClearFields();
          this.submitted = false;
          this.fnBindModel();
          this.fnBindLastUpdatedByModel();
        }
        else if (res == environment.constants.Duplicate) {
          this.notifyService.showError(environment.messageParams.msgDuplicateError, "Error")
        }

        else {
          this.notifyService.showError(environment.messageParams.msgSaveError, "Error")
        }
      }
      else {
        this.notifyService.showError(environment.messageParams.msgSaveError, "Error")
      }
    },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      })
  }
  fnBindDeleteMapping(e) {
    //Getting ID to pass to Delete API 
    let _mfid = e.rowData.MapID;

    this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/DeleteModelSerialnumber?id=' + _mfid, 'post', null).subscribe(res => {


      if (res) {
        if (res == environment.constants.Success) {
          this.notifyService.showSuccess(environment.messageParams.msgModelSerialNumberMappingDeleted, "Success")
          //Reload the grid
          this.searchFunc()
        } else { this.notifyService.showError(environment.messageParams.msgDeleteError, "Error") }
        this.fnBindSearchGridResultAPI('');
      }
      else {
        this.notifyService.showError(environment.messageParams.msgDeleteError, "Error")
      }
    },
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      })
  }
  searchFunc() {
    if (this.searchForm.value.search_model_number == "" && this.searchForm.value.search_serial_number == "") {
      this.search_form_valid = false
      return;
    }
    this.clearButtonShow=true
    this.fnBindSearchGridResultAPI(this.searchForm.value)
    this.export=false;
  }

  fnBindModel() {
    //Binding ModelNumber Dropdown from API  

    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetModel?searchtext='+this.searchText, 'get', null).subscribe(
      res => {

        if (res != null) {
          this.model_number = res["TBL_Result"];



          this.filteredOptions = this.form.controls['selectedModel'].valueChanges.pipe(startWith(''),
            map(x => typeof x === 'string' ? x : x.ModelName),
            map(ModelName => ModelName ? this._filter(ModelName) : this.model_number.slice())
          );
        }
      }, (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      });
  }

  /*Fn to fetch Last ModifiedBy and ModifiedDate of model */
  fnBindLastUpdatedByModel() {
    //Binding Last CreatedBy and Date from API  
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetLastModifiedModel?_tableName=ModelSerialNumber', 'get', null).subscribe(
      res => {
        if (res != null) {          
          this.lastUpdateds = res["TBL_Result"]; 
        }
      }, (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      });
  }

  private _filter(name: string): Model[] {
    const filterValue = name.toLowerCase();

    return this.model_number.filter(option => option.ModelName.toLowerCase().indexOf(filterValue) === 0);
  }
  onSelectModelNumber(event: any) {

    //this.search_form_valid = false
    this.model.selectedModel = event.option.value['ModelID'];

  }
  //Filteration for autocomplete of ModelNumber
  private filterModelNum(val: string): string[] {
    return this.model_number.map(x => x.ModelName).filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  /*Fn to bind model number for search fileld */
  fnBindModelSearch() {
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetModel?searchtext='+this.searchText1,'get', null).subscribe(
      res => {

        if (res != null) {
          this.model_number_search = res["TBL_Result"];
          //Binding for autocomplete for modelNumber
          this.searchFilteredOptions = this.searchForm.controls['search_model_number'].valueChanges.pipe(startWith(''), map(val => this.filterModelNumSearch(val)));


        }
      }, (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      });
  }
  //Filteration for autocomplete of ModelNumber
  private filterModelNumSearch(val: string): string[] {
    return this.model_number_search.map(x => x.ModelName).filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }
  /*load grid values based on search*/
  fnBindSearchGridResultAPI(_searchItems) {
    this.search_form_valid = true;
    this.ngxLoader.start();
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetModelSerialNumber?_modelnumber=' + (_searchItems.search_model_number == undefined ? '' : _searchItems.search_model_number) + '&_slNumber=' + (_searchItems.search_serial_number == undefined ? '' : _searchItems.search_serial_number), 'get', null).subscribe(
      res => {
        this.ngxLoader.stop();
        if (res != null) {
          this.rowData = res["TBL_Result"];
          this.showGrid = true;
        }
      },
      (error: AppError) => {
        this.ngxLoader.stop();
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      }
    );
  }

  displayFn(z: Model): string {
    return z && z.ModelName ? z.ModelName : '';
  }
  /*load grid values based on search*/

  fnClearFields() {
     this.fnBindMappingForm()   
    // this.form.controls['selectedModel'].setValue('');
    // this.form.controls['SerialNumber'].setValue(null);
    // this.form.controls['ProductionDate'].setValue(null);
  }

  refreshSearchGrid(){
    console.log("search clear")
    this.searchForm.controls['search_model_number'].setValue('');
    this.searchForm.controls['search_serial_number'].setValue(null);
    this.fnBindSearchGridResultAPI('')
    this.fnBindLastUpdatedByModel();
    this.clearButtonShow=false;
    this.export=false;
    
  }



  onKeyUp(event) {
     this.searchText = event.target.value;
    if (this.searchText.length > 0) {
      this.fnBindModel();
     }
    else {
      this.model_number = [];
    }
  }

  onKeyUp1(event) {
    this.searchText1 = event.target.value;
   if (this.searchText1.length > 0) {
     this.fnBindModelSearch();
    }
   else {
     this.model_number = [];
   }
 }

 exportAsXLSX(){
  this.export=true;
  const SerialModelList = [];
  this.rowData.forEach((item) => {
      SerialModelList.push({
        'Model Number': item.ModelName,
        'Serial Number': item.SerialNumber,
        'Production Date': item.ManufactureDate,
      });
    });
  this.msalService.exportAsExcelFile(SerialModelList, 'SerialNumber-Model');
}

}//close of export




