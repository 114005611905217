import { Component, OnInit, ViewChild } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators,ValidatorFn,AbstractControl,ValidationErrors } from '@angular/forms';
import { CommonService } from '../Common-Utils/Services/common.service';
import { MsaluserService } from '../msaluser.service';
import { environment } from 'src/environments/environment';
import { ButtonRendererEditComponent } from '../Common-Utils/components/button-renderer-edit/button-renderer-edit.component';
import { NotificationService } from '../Common-Utils/Services/notification.service'
import { UIErrorHandler } from '../Common-Utils/globalerrorhandler/UIErrorHandler';
import { AppError } from '../Common-Utils/globalerrorhandler/AppError';
import * as $ from 'jquery'
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
@Component({
  selector: 'app-model-family',
  templateUrl: './model-family.component.html',
  styleUrls: ['./model-family.component.css']
})

export class ModelFamilyComponent implements OnInit {

  faPlusSquare = faPlusSquare;
  faSearch = faSearch;
  faEraser=faEraser;
  searchModel: any;
  modelfamilySidebar: string
  showGrid: boolean = false
  headerstring: string = environment.bannerTexts.modelFamilyText
  @ViewChild('modelfamily') modelfamily;
  @ViewChild('closeModelFamily') closeModelFamily;

  form: FormGroup;
  id: number;
  isAddMode: boolean = true;
  loading = false;
  submitted = false;
  frameworkComponents: any;
  lblSearch = '';

  private gridApi;
  private gridColumnApi;
  private editType;
  private defaultColDef;
  public _genericErrorHandler = null;
  clearButtonShow:boolean = false;
  constructor(

    private formBuilder: FormBuilder,
    private router: Router,
    private _commonService: CommonService,
    private msalService: MsaluserService,
    private notifyService: NotificationService

  ) {

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererEditComponent,
    }
    this.defaultColDef = {
      sortingOrder: ["asc", "desc"],
      component : environment.bannerTexts.modelFamilyText,
      stopEditingWhenGridLosesFocus: false,
      sortable: true,
      enableFilter: true,
      suppressKeyboardEvent: function (event) {
        if (!event.editing || event.event.code === "Enter")
          return true;
      }
    };
    this.editType = "fullRow";
  }
  columnDefs = [

    //Please donot change---added as per API response--Rama
    { headerName: 'Model Family Name', field: 'ModelFamilyName',minWidth :100,maxWidth: 350,flex: 4, resizable: true, editable: true },
    { headerName: 'Description', field: 'Description', resizable: true, minWidth: 100, maxWidth: 350, flex: 4, editable: true,tooltipField: 'Description' },
    {
      headerName: 'Status', field: 'Status', minWidth: 75, maxWidth: 100, editable: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values:environment.constants.statusDropdownValues//For Status dropdown
      }
    },
    {
      headerName: 'Action',
      field: 'action', minWidth: 100, maxWidth: 125,
      cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        cancelOtherRowEditors: this.cancelOtherRowEditors.bind(this),
        updateRowEditors: this.fnBindUpdateGrid.bind(this),//For edit--to get selected row values from button-renderededit component
        deleteRowEditors: this.fnDeleteModelFamily.bind(this)//For delete--to get selected row values from button-renderededit component

      },
    },
  ];
  rowData: any;


  ngOnInit(): void {

    this.modelfamilySidebar = "modelfamily"
    this.form = this.formBuilder.group({
      modalfamily: ['', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      description: [''],
      active: ['']
    });
    this._genericErrorHandler = new UIErrorHandler();
    //Calling binding grid result oninit
    this.searchModel='';
    this.fetchResults();
  }

  get f() { return this.form.controls; }
  fnNewForm() {
    this.form.reset();
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    //let ModelFamilyValidation = (this.form.controls["modalfamily"].value).trim()
    
    if (this.form.invalid ) {
     // this.notifyService.showError(environment.messageParams.msgInvalidName, "Error")
      return;
    }
    this.loading = true;
    if (this.isAddMode) {
      this.showGrid = false;
      this.createModelFamily();
    }
  }

  cancelOtherRowEditors(currentRowIndex) {
    //Renderer implies all rows of grid
    const renderers = this.gridApi.getCellRendererInstances();

    renderers.forEach(function (renderer) {
      if (!renderer._agAwareComponent.isNew &&
        currentRowIndex !== renderer._params.node.rowIndex) {
        renderer._agAwareComponent.onCancelClick();
      }
    });
  }

  /*fnBindUpdateGrid -Calling Update API  */
  fnBindUpdateGrid(e) {
    console.log("Update grid value", e)
    if(e.selectedData['ModelFamilyName']=='' || e.selectedData['ModelFamilyName']==undefined){
      this.notifyService.showError(environment.messageParams.msgModelFamilyEmpty, "Error")
      this.fetchResults()
      return;
    }
    const updateModelFamilyData: any = {
      'ModelFamilyID': e.selectedData['ModelFamilyID'],
      'ModelFamilyName': e.selectedData['ModelFamilyName'],
      'Description': e.selectedData['Description'],
      'Status': e.selectedData['Status'] == 'Active' ? true : false,
      'ModifiedBy': this.msalService.getCurrentUserInfo()

    };
    console.log('updateModelFamilyData', updateModelFamilyData)
    //Update API call
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Maintenance/UpdateModelFamily', 'post', updateModelFamilyData).subscribe(res => {
      console.log('Updated successfully!', res);
      if (res) {
        if (res == environment.constants.Success) {
          this.fetchResults();
          this.notifyService.showSuccess(environment.messageParams.msgModelFamilyUpdate, "Success")
        }else{
          this.notifyService.showError(environment.messageParams.msgModelFamilyDuplicateError, "Error")
        }
           //Reloading grid after duplicate or success
           this.fetchResults()
      }
      else {
        this.notifyService.showError(environment.messageParams.msgUpdateError, "Error")
      }


    }, (error: AppError) => {
      this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      this.fetchResults();
    })

  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }

  onCellClicked(params) {
    if (params.node.field !== 'action') {
      this.cancelOtherRowEditors(params.node.rowIndex);
    }
  }


  //Calling Delete API
  fnDeleteModelFamily(e) {

    //Getting ID to pass to Delete API 
    let _mfid = e[0]['ModelFamilyID'];
    console.log('mfid', _mfid);
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Maintenance/DeleteModelFamily?id=' + _mfid, 'post', null).subscribe(res => {

      console.log('res-del', res);
      if (res) {
        if (res == environment.constants.Success) {
          //Reloading grid after delete successful
          this.fetchResults();
          this.notifyService.showSuccess(environment.messageParams.msgModelFamilyDeleted, "Success")
        } else if (res == environment.constants.MappingExists) {
          this.notifyService.showWarning(environment.messageParams.msgModelFamilyDeleteMappingExists, "Warning")
        }
      }
      else {
        this.notifyService.showError(environment.messageParams.msgDeleteError, "Error")
      }
    },
    (error: AppError) => {
      this._genericErrorHandler.handleError(error, this.notifyService, this.router);
    })
  }


  /*createModelFamily - To create new Model Family*/
  private createModelFamily() {
    this.loading = false;
    console.log('form value', this.form.value);
    const addModelFamilyData: any = {
      'ModelFamilyName': (this.form.controls["modalfamily"].value).trim(),
      'Description': this.form.controls["description"].value,
      'Status': true,
      'CreatedBy': this.msalService.getCurrentUserInfo()

    };
    console.log('aa', addModelFamilyData);
    //return;
    this._commonService.fnCallWebAPI(environment.baseUrl + 'Maintenance/InsertModelFamily', 'post', addModelFamilyData).subscribe(res => {
      console.log('Family created successfully!', res);
      if(res){
        if(res==environment.constants.Success){
          this.notifyService.showSuccess(environment.messageParams.msgModelFamilyAdd, "Success")
          this.router.navigateByUrl('admin/model-family');
          this.closeModel();
          this.showGrid = true;
          this.searchModel = '';
          //Reload grid after creating new model family
          this.fetchResults();
        }else{
          this.notifyService.showError(environment.messageParams.msgModelFamilyDuplicateError,"Error")
          this.searchModel = '';
        }
      }else{
        this.notifyService.showError(environment.messageParams.msgSaveError, "Error")
      }
     
     

     
      //alert('Model Family created successfully!!');

    },
    (error: AppError) => {
      this._genericErrorHandler.handleError(error, this.notifyService, this.router);
    })

  }


  /* To load grid data based on search */
  fetchResults() {
    console.log('fetch', this, this.searchModel);
   // if (this.searchModel != undefined && this.searchModel != "") {
      this._commonService.fnCallWebAPI(environment.baseUrl + 'Search/GetModelFamily?getAll=true&searchtext=' + this.searchModel, 'get', null).subscribe(
        res => {
          console.log('api res', res);
          if (res != null) {
            this.rowData = res["TBL_Result"];
            this.showGrid = true;
            console.log('griddata', this.rowData);
            this.lblSearch = '';
            if(this.searchModel!=''){
              this.clearButtonShow=true; 
            }    

          }
        }
      ),
      (error: AppError) => {
        this._genericErrorHandler.handleError(error, this.notifyService, this.router);
      }
      ;
 //   }
    // else {
    //   this.lblSearch = 'Please enter search text';
    //   this.showGrid = false;
    // }

  }

  /*Model popup functions*/
  openModel() {
    this.submitted =false
    this.form.reset()
   
  }
  closeModel() {
   
    this.closeModelFamily.nativeElement.click();
    $('.modal-backdrop').remove();
  }
  /*Model popup functions Ends*/
  refreshSearchGrid(){
    console.log("search clear")
    this.searchModel='';
    this.fetchResults();
    this.clearButtonShow=false;
    
    
  }

}//close of export