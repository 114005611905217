<app-banner [headerstring]="headerstring"></app-banner>
<div class="card">
  <!-- <div class="card-header" id="searchFilter">
    <a
      title="Click to hide/show"
      data-toggle="collapse"
      data-target="#inputSearch"
      class="align-center"
    >
      <span class="glyphicon glyphicon-fullscreen"></span>
      <div class="cur-pointer">
        <u>Click to hide/unhide the search entry section!</u>
      </div>
    </a>
  </div> -->
  <div
    id="inputSearch"
    class="active collapse show"
    aria-labelledby="searchFilter"
  >
    <div>
      <app-input-search
        (SearchResponse)="getSearchResponse($event)"
        (SearchType)="handleSearchTypeChange($event)"
      ></app-input-search>
    </div>
  </div>
</div>

<div class="container">
  <span *ngIf="!isReset && searchResult && searchResult.length == 0">
    <div class="search-result-top-2em">
      <table class="table table-bordered alert-danger">
        <tbody>
          <tr>
            <td>
              No Records Found.
              <span *ngIf="searchType === 0">
                Please search with the “Model” or “Model Family” option.
              </span>
              <span *ngIf="searchType === 1">
                Please search with the "Serial Number" or “Model Family” option.
              </span>
              <span *ngIf="searchType === 2">
                Please search with the “Serial Number” or “Model” option.
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </span>
  <app-search-results
    *ngIf="searchResult"
    [resultedFluids]="searchResult"
  ></app-search-results>
</div>
