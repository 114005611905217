import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from '../Common-Utils/Services/common.service';
import { NotificationService } from '../Common-Utils/Services/notification.service'
import { MsaluserService } from '../msaluser.service';
import { faUpload, faDownload, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import { UIErrorHandler } from '../Common-Utils/globalerrorhandler/UIErrorHandler';
import { AppError } from '../Common-Utils/globalerrorhandler/AppError';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
// declare var XLS: any;

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  faUpload=faUpload
  faDownload=faDownload
  faFileDownload=faFileDownload
  localUrl: any[];
  _fileType: string = null;
  _fileTypeSelected = false;
  _isValidColumns = true;
  _isBlank = false;
  _columnsNotAvailable: string = '';
  _invalidColumns: string = '';
  fileupload: string;
  headerstring: string = environment.bannerTexts.fileUploadText
  public _genericErrorHandler = null;
  constructor(
    private _commonService: CommonService,
    private msalService: MsaluserService,
    private notifyService: NotificationService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.msalService.initializeAuthentication();
    this.fileupload = "fileupload"
    this._genericErrorHandler = new UIErrorHandler();
  }

  fnUploadButtonClick() {
    let arrayBuffer;
    //For Loader
    this.ngxLoader.start();

    // if (this._isFileAvailable) {
    const fileRef = this.fileInput.nativeElement.files;
    const reader = new FileReader();
    if (fileRef && fileRef.length > 0) {
      const file = fileRef[0];
      // Check if file is of a valid format.CSV Allowed
      const _fileExtn = file.name.substring(file.name.lastIndexOf('.') + 1);
      if (_fileExtn.toLowerCase() !== 'xlsx') {
        this.ngxLoader.stop();
        this.notifyService.showError(environment.messageParams.msgInvalidFile, "Error");
        return false;
      }
      // Check if the file uploaded is xlsx, if yes read the data as JSON
      if (_fileExtn.toLowerCase() === 'xlsx') {
        
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
          arrayBuffer = reader.result;
          const data = new Uint8Array(arrayBuffer);
          const arr = new Array();
          for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
          const bstr = arr.join('');
          // const workbook = XLSX.read(bstr, { type: 'binary' });
          const workbook = XLSX.read(bstr, { type: 'binary' });
          const first_sheet_name = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[first_sheet_name];
          let fileJsonData: any;
          fileJsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: null, blankrows: true });

          if (this._fileType === '1') {
            this.fnValidateModelfamilyModel(fileJsonData);
          }
          else if (this._fileType === '2') {
            this.fnValidateModelSerialNumber(fileJsonData);
          }
          else if (this._fileType === '3') {
            this.fnValidateModelFamilySpecification(fileJsonData);
          }
        };        
        return true;
      }
    }
    else{
      this.notifyService.showError(environment.messageParams.msgNoFileUploaded, "Error")
    }
    this.ngxLoader.stop();
  }

  onFileTypeSelectionChange(value): void {
    this._fileType = value;
    this._fileTypeSelected = true;
  }

  fnValidateModelfamilyModel(fileJsonData: any) {
    if (fileJsonData === undefined || fileJsonData.length === 0) {
      this._isBlank = true;
      this.ngxLoader.stop();
      this.notifyService.showError(environment.messageParams.msgInvalidFile, "Error");
    }
    else if (fileJsonData) {
      const inputColumns = Object.keys(fileJsonData[0]);
      environment.bulkuploadParams.modelFamilyModelHeaderColumns.forEach(element => {
        if (inputColumns.indexOf(element) === -1) {
          this._columnsNotAvailable = this._columnsNotAvailable + element + ', ';
          this._isValidColumns = false;
        }
      });
      inputColumns.forEach(element => {
        if (environment.bulkuploadParams.modelFamilyModelHeaderColumns.indexOf(element) === -1) {
          this._invalidColumns = this._invalidColumns + element + ', ';
          this._isValidColumns = false;
        }
      });
      if (this._isValidColumns && !this._isBlank) {
        this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/UploadModelFamilyModelData', 'post', fileJsonData).subscribe(res => {
          if (res) {
            if (res == environment.constants.Success) {
              this.fileInput.nativeElement.value = "";
              this.notifyService.showSuccess(environment.messageParams.msgFileUploadSuccess, "Success")
              this.ngxLoader.stop();
            } else {
              this.ngxLoader.stop();
              this.notifyService.showError(environment.messageParams.msgFileUploadFailure, "Error")
            }
          }
          else {
            this.ngxLoader.stop();
            this.notifyService.showError(environment.messageParams.msgFileUploadFailure, "Error")
          }
        },
        (error: AppError) => {
          this._genericErrorHandler.handleError(error, this.notifyService, this.router);
          this.ngxLoader.stop();
        });
        
      }
      else {
        this.ngxLoader.stop();
        // this.notifyService.showError(environment.messageParams.msgInvalidFile, "Error")
        this.notifyService.showError(environment.messageParams.msgInvalidFile +  (this._invalidColumns === null ? this._invalidColumns : ' Column headers are invalid or missing. ') + (this._columnsNotAvailable === null ?  this._columnsNotAvailable : ' A header row is required with the column names specified in any order. Valid column names are: ' + this._columnsNotAvailable), "Error")
        this._invalidColumns = '';
        this._columnsNotAvailable = '';
      }
    }
  }

  fnValidateModelSerialNumber(fileJsonData: any) {
    if (fileJsonData === undefined || fileJsonData.length === 0) {
      this._isBlank = true;
      this.ngxLoader.stop();
      this.notifyService.showError(environment.messageParams.msgInvalidFile, "Error");
    }
    else if (fileJsonData) {
      const inputColumns = Object.keys(fileJsonData[0]);
      environment.bulkuploadParams.modelSerialNumberHeaderColumns.forEach(element => {
        if (inputColumns.indexOf(element) === -1) {
          this._columnsNotAvailable = this._columnsNotAvailable + element + ', ';
          this._isValidColumns = false;
        }
      });
      inputColumns.forEach(element => {
        if (environment.bulkuploadParams.modelSerialNumberHeaderColumns.indexOf(element) === -1) {
          this._invalidColumns = this._invalidColumns + element + ', ';
          this._isValidColumns = false;
        }
      });
      if (this._isValidColumns && !this._isBlank) {
        console.log("fileJsonData",fileJsonData);
        this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/UploadModelSerialNumberData', 'post', fileJsonData).subscribe(res => {
          if (res) {
            if (res == environment.constants.Success) {
              this.fileInput.nativeElement.value = "";
              this.notifyService.showSuccess(environment.messageParams.msgFileUploadSuccess, "Success")
              this.ngxLoader.stop();
            } else {
              this.notifyService.showError(environment.messageParams.msgFileUploadFailure, "Error")
            }
          }
          else {
            this.notifyService.showError(environment.messageParams.msgFileUploadFailure, "Error")
          }
          this.ngxLoader.stop();
        },(error: AppError) => {
          this._genericErrorHandler.handleError(error, this.notifyService, this.router);
          this.ngxLoader.stop();
        });
      } else {
        this.ngxLoader.stop();
        // this.notifyService.showError(environment.messageParams.msgInvalidFile, "Error");
        this.notifyService.showError(environment.messageParams.msgInvalidFile +  (this._invalidColumns === null ? this._invalidColumns : ' Column headers are invalid or missing. ') + (this._columnsNotAvailable === null ?  this._columnsNotAvailable : ' A header row is required with the column names specified in any order. Valid column names are: ' + this._columnsNotAvailable), "Error")
        this._invalidColumns = '';
        this._columnsNotAvailable = '';
      }
    }
  }

  fnValidateModelFamilySpecification(fileJsonData: any) {
    if (fileJsonData === undefined || fileJsonData.length === 0) {
      this._isBlank = true;
      this.ngxLoader.stop();
      this.notifyService.showError(environment.messageParams.msgInvalidFile, "Error");
    }
    else if (fileJsonData) {
      const inputColumns = Object.keys(fileJsonData[0]);
      environment.bulkuploadParams.modelFamilySpecificationHeaderColumns.forEach(element => {
        if (inputColumns.indexOf(element) === -1) {
          this._columnsNotAvailable = this._columnsNotAvailable + element + ', ';
          this._isValidColumns = false;
        }
      });
      inputColumns.forEach(element => {
        if (environment.bulkuploadParams.modelFamilySpecificationHeaderColumns.indexOf(element) === -1) {
          this._invalidColumns = this._invalidColumns + element + ', ';
          this._isValidColumns = false;
        }
      });
      if (this._isValidColumns && !this._isBlank) {
        console.log("fileJsonData",fileJsonData);
        this._commonService.fnCallWebAPI(environment.baseUrl + 'Mapping/UploadModelFamilySpecificationData', 'post', fileJsonData).subscribe(res => {
          if (res) {
            if (res == environment.constants.Success) {
              this.fileInput.nativeElement.value = "";
              this.notifyService.showSuccess(environment.messageParams.msgFileUploadSuccess, "Success")
              this.ngxLoader.stop();
            } else {
              this.notifyService.showError(environment.messageParams.msgFileUploadFailure, "Error")
            }
          }
          else {
            this.notifyService.showError(environment.messageParams.msgFileUploadFailure, "Error")
          }
          this.ngxLoader.stop();
        },(error: AppError) => {
          this._genericErrorHandler.handleError(error, this.notifyService, this.router);
          this.ngxLoader.stop();
        });
      }
      else {
        this.ngxLoader.stop();
        // this.notifyService.showError(environment.messageParams.msgInvalidFile, "Error")
        this.notifyService.showError(environment.messageParams.msgInvalidFile +  (this._invalidColumns === null ? this._invalidColumns : ' Column headers are invalid or missing. ') + (this._columnsNotAvailable === null ?  this._columnsNotAvailable : ' A header row is required with the column names specified in any order. Valid column names are: ' + this._columnsNotAvailable), "Error")
        this._invalidColumns = '';
        this._columnsNotAvailable = '';
      }
    }
  }
  fnDownloadTemplate(fileUrl: string, fileName: string ) {
    FileSaver.saveAs(fileUrl, fileName);
  }
}
